// External Imports
import React, { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//Components
import ProcessingAlert from './alerts/ProcessingAlert';

//Hooks
import useToken from '../utils/useToken';

//Main View
export default function Users() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [users, setUsers] = useState([]);

  const [usersChanged, changeUsers] = useState(true);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getUsers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((users) => {
        return users.map((user) => {
          var status = 'info';
          if (user.enabled && user.email_verified && user.customer_group) {
            if ('customer_ids' in user.attributes) {
              if (user.attributes.customer_ids.length == 0) {
                status = 'warning';
              } else {
                status = 'success';
              }
            } else {
              status = 'warning';
            }
          } else {
            status = 'danger';
          }
          return { ...user, status: status };
        });
      })
      .then((users) => {
        // TODO: SORT HERE ?
        return users;
      })
      .then((users) => {
        changeUsers(false);
        setUsers(users);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve users!', severity: 'danger' });
      });
  };

  useEffect(() => {
    if (usersChanged) {
      getUsers();
    }
  }, [usersChanged]);

  // Event Handlers
  const changeEnabled = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const id = values.target.value;
    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users/' + id + '/enabled', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((users) => {
        changeUsers(true);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not enable/disable user!', severity: 'danger' });
      });
  };

  const verifyMail = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const id = values.target.value;
    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users/' + id + '/verify', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((users) => {
        changeUsers(true);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not send verification mail!', severity: 'danger' });
      });
  };

  const assignGroup = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const id = values.target.value;
    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/group/customers/users/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((users) => {
        changeUsers(true);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not allocate to -customers- group!', severity: 'danger' });
      });
  };

  const popover = (customer_ids) => {
    return (
      <Popover id='popover-basic'>
        <Popover.Header as='h3'>Allowed Customers</Popover.Header>
        <Popover.Body>
          <ul>
            {customer_ids.map((id) => (
              <li key={id}>{id}</li>
            ))}
          </ul>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Configuration - Customer Access</h1>
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Card>
            <Card.Header>Users</Card.Header>
            <Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>E-Mail</th>
                    <th>Name</th>
                    <th>Enabled</th>
                    <th>Customer Group</th>
                    <th>Customers</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id} className={'table-' + user.status}>
                      {user.email_verified ? (
                        <th className='table-success'>{user.username}</th>
                      ) : (
                        <th className='table-danger'>
                          {user.username}
                          <br />
                          <Button variant='primary btn-sm' value={user.id} onClick={verifyMail}>
                            Verifify Mail
                          </Button>
                        </th>
                      )}
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <th className={user.enabled ? 'table-success' : 'table-danger'}>
                        <Form.Check
                          type='switch'
                          name='enabled'
                          value={user.id}
                          placeholder='Enabled'
                          checked={user.enabled}
                          onChange={changeEnabled}
                        />
                      </th>
                      {user.customer_group ? (
                        <th className='table-success'>
                          <Form.Check
                            type='switch'
                            name='customer_group'
                            placeholder='Customer Group'
                            checked
                            disabled
                          />
                        </th>
                      ) : (
                        <th className='table-danger'>
                          <Button variant='primary btn-sm' value={user.id} onClick={assignGroup}>
                            Approve Customer
                          </Button>
                        </th>
                      )}
                      <td>
                        {'customer_ids' in user.attributes ? (
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement='right'
                            overlay={popover(user.attributes.customer_ids)}
                          >
                            <span>{user.attributes.customer_ids.length}</span>
                          </OverlayTrigger>
                        ) : (
                          <>0</>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
