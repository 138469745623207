// External Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

// Login Window
export default function Login({ setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError({ message: 'Processing...', severity: 'info' });

    const loginObj = {
      username: username,
      password: password,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginObj),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setToken(data.access_token);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        if (error.status === 401) {
          setError({ message: 'Invalid username or password!', severity: 'warning' });
        } else {
          setError({ message: 'Could not connect to authentication server!', severity: 'danger' });
        }
      });
  };

  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Login</h1>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Header>Enter Credentials</Card.Header>
            <Card.Body>
              {error.message != '' && (
                <Alert variant={error.severity}>
                  <span>{error.message}</span>
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className='mb-3' controlId='username'>
                  <Form.Label column sm={3}>
                    Username
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      placeholder='Enter Username'
                      name='username'
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='password'>
                  <Form.Label column sm={3}>
                    Password
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='password'
                      placeholder='Enter Password'
                      name='password'
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Button variant='success' type='submit'>
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func,
};
