// External Imports
import React, { useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import Moment from 'moment';
import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

import { fetchEventTypes, fetchUsers } from '../../utils/audit';
import { fetchBandwidthPools } from '../../utils/bandwidth_pools';
import { getCustomerTree, fetchCustomers } from '../../utils/customers';
import { fetchHosts } from '../../utils/hosts';
import { fetchSites } from '../../utils/sites';

// Main View
export default function AuditReport() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [report, setReport] = useState([]);

  const [eventTypes, setEventTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bwPools, setBwPools] = useState([]);
  const [sites, setSites] = useState([]);
  const [subnets, setSubnets] = useState([]);
  const [users, setUsers] = useState([]);

  const [accordion, setAccordion] = useState('filter');

  const [error, setError] = useState({ message: '', severity: 'primary' });

  const now = new Date();
  const day1 = new Date('2023-12-01T00:00:00');
  const lastWeek = new Date();
  lastWeek.setDate(now.getDate() - 7); //TODO: SET TIME 00:00

  const { Formik } = formik;
  const schema = yup.object().shape({
    from_date: yup.date().min(day1.toDateString(), 'Can not show legacy data from OfBIZ!'), // required?
    to_date: yup.date().max(now.toDateString(), 'Can not show future data!'),
    event_type: yup.string(),
    customer_id: yup.string(),
    bw_pool: yup.string(),
    site_id: yup.string(),
    subnet: yup.string(),
    user: yup.string(),
  });

  // Load Filter data
  const getEventTypes = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchEventTypes(token)
      .then((types) => {
        setEventTypes(types);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve event types!', severity: 'danger' });
      });
  };

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchCustomers(token)
      .then((customers) => {
        const customerTree = getCustomerTree(customers);
        setCustomers(customerTree);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customers!', severity: 'danger' });
      });
  };

  const getBwPools = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchBandwidthPools(token)
      .then((data) => {
        return Object.values(data).filter((pool) => {
          return pool.customer_id !== null;
        });
      })
      .then((pools) => {
        setBwPools(pools);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve bandwidth pools! ' + error, severity: 'danger' });
      });
  };

  const getSites = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchSites(token)
      .then((sites) => {
        setSites(Object.values(sites));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve sites!', severity: 'danger' });
      });
  };

  const getSubnets = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchHosts(token)
      .then((hosts) => {
        setSubnets(hosts);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve subnets!', severity: 'danger' });
      });
  };

  const getUsers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchUsers(token)
      .then((users) => {
        setUsers(users);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve users!', severity: 'danger' });
      });
  };

  useEffect(() => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    getEventTypes();
    getCustomers();
    getBwPools();
    getSites();
    getSubnets();
    getUsers();
  }, []);

  // Event Handlers
  const getReport = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/reports/audit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setReport(data);
        if (data.length > 0) {
          setAccordion('');
          setError({ message: '', severity: 'success' });
        } else {
          setError({ message: 'No Data Found!', severity: 'warning' });
        }
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve report!', severity: 'danger' });
        setAccordion('filter');
      });
  };

  const selectAccordion = (e) => {
    setAccordion(e);
  };

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Audit Log</h1>
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>

      <Accordion className='mb-3' onSelect={selectAccordion} activeKey={accordion}>
        <Accordion.Item eventKey='filter'>
          <Accordion.Header>Filter Events</Accordion.Header>
          <Accordion.Body>
            <Formik
              validationSchema={schema}
              onSubmit={getReport}
              initialValues={{
                from_time: lastWeek,
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group as={Row} className='mb-1' controlId='from_time'>
                    <Form.Label column sm={2}>
                      From Date/Time
                    </Form.Label>

                    <Col sm={2}>
                      <Datetime
                        value={values.from_time}
                        dateFormat='YYYY-MM-DD'
                        timeFormat='HH:mm'
                        onChange={async (ms) => {
                          setFieldValue('from_time', ms); // TODO FORMATTING ?
                        }}
                      />
                      <Form.Control
                        type='hidden'
                        name='from_time'
                        value={values.from_time}
                        isInvalid={!!errors.from_time}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.from_time}
                      </Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='to_time'>
                    <Form.Label column sm={2}>
                      Until Date/Time
                    </Form.Label>

                    <Col sm={2}>
                      <Datetime
                        value={values.to_time}
                        dateFormat='YYYY-MM-DD'
                        timeFormat='HH:mm'
                        onChange={async (ms) => {
                          setFieldValue('to_time', ms); // TODO FORMATTING ?
                        }}
                      />
                      <Form.Control
                        type='hidden'
                        name='to_time'
                        value={values.to_time}
                        isInvalid={!!errors.to_time}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.to_time}</Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='event_type'>
                    <Form.Label column sm={2}>
                      Event Type
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select an event type'
                        name='event_type'
                        value={values.event_type}
                        isInvalid={!!errors.event_type}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select an event type--
                        </option>

                        {eventTypes.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>
                        {errors.event_type}
                      </Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='customer_id'>
                    <Form.Label column sm={2}>
                      Customer
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select a customer'
                        name='customer_id'
                        value={values.customer_id}
                        isInvalid={!!errors.customer_id}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select a customer--
                        </option>

                        {customers.map((customer) => (
                          <option key={customer.customer_id} value={customer.customer_id}>
                            {customer.prefix} [{customer.customer_id}] {customer.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>
                        {errors.customer_id}
                      </Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='bw_pool'>
                    <Form.Label column sm={2}>
                      Bandwidth Pool
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select a bandwidth pool'
                        name='bw_pool'
                        value={values.bw_pool}
                        isInvalid={!!errors.bw_pool}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select a bandwidth pool--
                        </option>

                        {bwPools.map((pool) => (
                          <option key={pool.pool_name} value={pool.pool_name}>
                            {pool.pool_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.bw_pool}</Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='site_id'>
                    <Form.Label column sm={2}>
                      Site
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select a site'
                        name='site_id'
                        value={values.site_id}
                        isInvalid={!!errors.site_id}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select a site--
                        </option>

                        {sites.map((site) => (
                          <option key={site.site_id} value={site.site_id}>
                            [{site.site_id}] {site.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.site_id}</Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='subnet'>
                    <Form.Label column sm={2}>
                      Subnet
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select a subnet'
                        name='subnet'
                        value={values.subnet}
                        isInvalid={!!errors.subnet}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select a subnet--
                        </option>

                        {Object.keys(subnets).map((ip) => (
                          <option key={ip} value={subnets[ip].subnet}>
                            {subnets[ip].subnet} ({subnets[ip].host_type} for {subnets[ip].site_id})
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.subnet}</Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className='mb-1' controlId='user'>
                    <Form.Label column sm={2}>
                      User
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Select
                        aria-label='Select a user'
                        name='user'
                        value={values.user}
                        isInvalid={!!errors.user}
                        onChange={handleChange}
                      >
                        <option key='n/a' value=''>
                          --Select a user--
                        </option>

                        {users.map((user) => (
                          <option key={user} value={user}>
                            {user}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.user}</Form.Control.Feedback>
                    </Col>
                    <Form.Text as={Col} muted></Form.Text>
                  </Form.Group>

                  <Button variant='success' type='submit'>
                    Show
                  </Button>
                </Form>
              )}
            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {report.length > 0 && (
        <Card>
          <Card.Header>Event Report</Card.Header>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Customer</th>
                  <th>BW Pool</th>
                  <th>Site</th>
                  <th>Subnet</th>
                  <th>Old Data</th>
                  <th>New Data</th>
                  <th>Reason</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {report.map((event) => (
                  <tr key={event['posted_at']}>
                    <th>
                      {event['event_type']}
                      <br />
                      <p className='fst-italic fw-light'>
                        {Moment(event['posted_at']).format('YYYY-MM-DD HH:mm:ss')}
                      </p>
                    </th>
                    <td>
                      {event['customer_id'] != '' && (
                        <>
                          <a
                            className='btn btn-primary mb-2'
                            href={'/customers/' + event['customer_id']}
                          >
                            {event['customer_id']}
                          </a>
                          <br />
                          {event['customer_name']}
                        </>
                      )}
                    </td>
                    <td>{event['bw_pool']}</td>
                    <td>
                      {event['site_id'] != '' && (
                        <>
                          <a className='btn btn-primary mb-2' href={'/sites/' + event['site_id']}>
                            {event['site_id']}
                          </a>
                          <br />
                          {event['site_name']}
                        </>
                      )}
                    </td>
                    <td>{event['subnet']}</td>
                    <td>
                      <span style={{ whiteSpace: 'pre-wrap' }}>{event['old_data']}</span>
                    </td>
                    <td>
                      {event['new_data'] == 'No Changes Made' ? (
                        <span className='text-danger'>{event['new_data']}</span>
                      ) : (
                        <span style={{ whiteSpace: 'pre-wrap' }}>{event['new_data']}</span>
                      )}
                    </td>
                    <td>{event['reason']}</td>
                    <td>{event['posted_by']}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
