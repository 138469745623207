// External Imports
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import * as formik from 'formik';
import * as yup from 'yup';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';

//Hooks
import useToken from '../../utils/useToken';

//Edit Table
export default function EditTransports({
  site,
  hosts,
  setHostsChanged,
  handleDelete,
  getAvailableVlans,
  getAvailableInterfaces,
  hostsReloading,
  setHostsReloading,
  setErrorUpstream,
}) {
  const { token, isValidToken } = useToken();

  //Local Data
  const siteId = site.site_id;

  const [error, setError] = useState({ message: '', severity: 'primary' });
  const [deleteOpen, openDelete] = useState('');
  const [editOpen, openEdit] = useState('');
  const [passwordVisible, setpasswordVisible] = useState(false);

  const [vlansLoading, setVlansLoading] = useState(true);
  const [availableVlans, setAvailableVlans] = useState([]);

  const [deviceGroups, setDeviceGroups] = useState([]);
  const [deviceInterfaces, setDeviceInterfaces] = useState([]);

  const bgp_schema = yup.object().shape({
    subnet: yup.string().required(),
    alias: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_ \-]+$/,
        'Only alphanumeric characters, spaces, underscores (_) and dashes (-) are allowed.',
      ),
    hiera_path: yup.string().required(),
    interface_name: yup.string().required(),
    vlan_id: yup.number().min(1).max(4096).required(),
    priority: yup.string().required(),
    redundancy_type: yup.string().required(),
    pop_redundancy: yup.string().required(),
  });
  const { Formik } = formik;

  // Calculated Data
  const pppoe = hosts.filter((host) => {
    return host.transport_type === 'pppoe';
  });
  const bgp = hosts.filter((host) => {
    return host.transport_type === 'bgp';
  });

  const updateAvailableVlans = async () => {
    const vlans = await getAvailableVlans('Customer BGP Transport');
    setAvailableVlans(vlans);
    setVlansLoading(false);
  };

  const updateAvailableInterfaces = async () => {
    const dev_interfaces = await getAvailableInterfaces('bgp');
    setDeviceGroups(dev_interfaces);
  };

  useEffect(() => {
    updateAvailableVlans();
    updateAvailableInterfaces();
  }, []);

  // Event Handlers
  const togglePassword = (event) => {
    setpasswordVisible(!passwordVisible);
  };

  const showDelete = (event) => {
    setError({ message: '', severity: 'success' });
    setErrorUpstream({ message: '', severity: 'success' });

    openEdit('');
    openDelete(event.target.value);
  };

  const showEdit = (event) => {
    setError({ message: '', severity: 'success' });
    setErrorUpstream({ message: '', severity: 'success' });

    openDelete('');
    openEdit(event.target.value);

    const selected = hosts.filter((host) => {
      return host.subnet === event.target.value;
    });
    if (selected != undefined && selected.length > 0) {
      deviceGroups.map(function (group) {
        if (group.hiera_path === selected[0].hiera_path) {
          setDeviceInterfaces(Object.keys(group.interfaces));
        }
      });
    }
  };

  const handleLocalDelete = async (values) => {
    const res = await handleDelete(values);
    if (res) {
      openDelete('');
      openEdit('');
    }
  };

  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const updatedTransport = {
      site_id: siteId,
      ...values,
    };
    const ip = values.subnet.split('/')[0];

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/transport/bgp/' + ip, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(updatedTransport),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setHostsReloading('transport');
          setError({
            message: 'BGP Transport update has been requested.',
            severity: 'success',
          });
          resetForm();
          openDelete('');
          openEdit('');
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update BGP transport! ' + error, severity: 'danger' });
      });
  };

  const updateDeviceInterfaces = async (hiera_path) => {
    deviceGroups.map(function (group) {
      if (group.hiera_path === hiera_path) {
        setDeviceInterfaces(Object.keys(group.interfaces));
      }
    });
  };

  // View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      {hostsReloading == 'transport' ? (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Reloading transports...</span>
        </Alert>
      ) : (
        <>
          {pppoe.length > 0 && (
            <Table striped>
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Transport Net</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {pppoe.map((host) => (
                  <Fragment key={host.subnet}>
                    <tr
                      key={host.subnet + '-view'}
                      className={
                        host.subscription.status == 'active'
                          ? 'table-success'
                          : host.subscription.status == 'suspended'
                          ? 'table-warning'
                          : 'table-danger'
                      }
                    >
                      <td>{host.alias}</td>
                      <td>{host.subnet}</td>
                      <td>{host.pppoe_username}</td>
                      <td>
                        {passwordVisible ? (
                          <span>{host.pppoe_password}</span>
                        ) : (
                          <Button variant='success' onClick={togglePassword}>
                            Show
                          </Button>
                        )}
                      </td>
                      <td>
                        {host.deleteable && deleteOpen !== host.subnet && (
                          <Button variant='danger' value={host.subnet} onClick={showDelete}>
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                    {deleteOpen === host.subnet && (
                      <tr key={host.subnet + '-delete'}>
                        <td colSpan={5}>
                          <GiveDeleteReason
                            formHandler={handleLocalDelete}
                            cancelHandler={showDelete}
                            extraValues={{ subnet: host.subnet }}
                          />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          )}
          {bgp.length > 0 && (
            <Table striped>
              <thead>
                <tr>
                  <th>Alias</th>
                  <th>Transport Net</th>
                  <th>VLAN</th>
                  <th>BGP Configuration</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {bgp.map((host) => (
                  <Fragment key={host.subnet}>
                    <tr
                      key={host.subnet + '-view'}
                      className={
                        host.subscription.status == 'active'
                          ? 'table-success'
                          : host.subscription.status == 'suspended'
                          ? 'table-warning'
                          : 'table-danger'
                      }
                    >
                      <td>{host.alias}</td>
                      <td>{host.subnet}</td>
                      <td>
                        <b>VLAN</b>
                        <br />
                        {host.vlan_id}
                        <br />
                        <b>Interface</b>
                        <br />
                        {host.interface_name}
                        <br />
                        <b>Device</b>
                        <br />
                        {host.hiera_path}
                      </td>
                      <td>
                        <b>ASN</b>
                        <br />
                        {site.asn}
                        <br />
                        <b>Session Red.</b>
                        <br />
                        {host.priority}
                        <br />
                        <b>Device Red.</b>
                        <br />
                        {host.redundancy_type}
                        <br />
                        <b>POP Red.</b>
                        <br />
                        {host.pop_redundancy}
                      </td>
                      <td>
                        {deleteOpen !== host.subnet && editOpen !== host.subnet && (
                          <>
                            <Button
                              variant='primary'
                              className='mb-2'
                              value={host.subnet}
                              onClick={showEdit}
                            >
                              Edit
                            </Button>

                            {host.deleteable && (
                              <Button variant='danger' value={host.subnet} onClick={showDelete}>
                                Delete
                              </Button>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                    {deleteOpen === host.subnet && (
                      <tr key={host.subnet + '-delete'}>
                        <td colSpan={5}>
                          <GiveDeleteReason
                            formHandler={handleLocalDelete}
                            cancelHandler={showDelete}
                            extraValues={{ subnet: host.subnet }}
                          />
                        </td>
                      </tr>
                    )}
                    {editOpen === host.subnet && (
                      <tr key={host.subnet + '-edit'}>
                        <td colSpan={5}>
                          <>
                            {error.message != '' && (
                              <Alert variant={error.severity}>
                                <span>{error.message}</span>
                              </Alert>
                            )}
                            <Formik
                              validationSchema={bgp_schema}
                              onSubmit={async (values, { resetForm }) => {
                                await handleForm(values, { resetForm });
                              }}
                              initialValues={{
                                asn: site.asn,
                                subnet: host.subnet,
                                alias: host.alias,
                                hiera_path: host.hiera_path,
                                interface_name: host.interface_name,
                                vlan_id: '' + host.vlan_id,
                                priority: host.priority,
                                redundancy_type: host.redundancy_type,
                                pop_redundancy: host.pop_redundancy,
                              }}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                values,
                                touched,
                                errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  <Form.Group as={Row} className='mb-3' controlId='asn'>
                                    <Form.Label column sm={4}>
                                      ASN
                                    </Form.Label>

                                    <Col sm={4}>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter AS Number'
                                        name='asn'
                                        value={site.asn}
                                        isInvalid={!!errors.asn}
                                        onChange={handleChange}
                                        disabled
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        {errors.asn}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='subnet'>
                                    <Form.Label column sm={4}>
                                      Subnet
                                    </Form.Label>

                                    <Col sm={8}>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter Subnet'
                                        name='asn'
                                        value={values.subnet}
                                        isInvalid={!!errors.subnet}
                                        onChange={handleChange}
                                        disabled
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        {errors.subnet}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='alias'>
                                    <Form.Label column sm={4}>
                                      Alias
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Control
                                        type='text'
                                        placeholder='Optionally enter alias for this BGP session'
                                        name='alias'
                                        value={values.alias}
                                        isInvalid={!!errors.alias}
                                        onChange={handleChange}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        {errors.alias}
                                      </Form.Control.Feedback>
                                    </Col>
                                    <Form.Text as={Col} muted></Form.Text>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='hiera_path'>
                                    <Form.Label column sm={4}>
                                      Device Group
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Select
                                        aria-label='Select the device group'
                                        name='hiera_path'
                                        value={values.hiera_path}
                                        isInvalid={!!errors.hiera_path}
                                        onChange={async (e) => {
                                          await handleChange(e);
                                          updateDeviceInterfaces(e.target.value);
                                        }}
                                      >
                                        <option key='' value=''>
                                          -- Select a device group --
                                        </option>
                                        {deviceGroups.map((intf) => (
                                          <option key={intf.device_group} value={intf.hiera_path}>
                                            {intf.device_group}
                                          </option>
                                        ))}
                                      </Form.Select>

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.hiera_path}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='interface_name'>
                                    <Form.Label column sm={4}>
                                      Interface
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Select
                                        aria-label='Select the interface'
                                        name='interface_name'
                                        value={values.interface_name}
                                        isInvalid={!!errors.interface_name}
                                        onChange={handleChange}
                                      >
                                        <option key='' value=''>
                                          -- Select an interface --
                                        </option>
                                        {deviceInterfaces.map((intf) => (
                                          <option key={intf} value={intf}>
                                            {intf}
                                          </option>
                                        ))}
                                      </Form.Select>

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.interface_name}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='vlan_id'>
                                    <Form.Label column sm={4}>
                                      VLAN
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Typeahead
                                        id='vlan_suggest'
                                        name='vlan_suggest'
                                        defaultInputValue={values.vlan_id}
                                        placeholder='Enter VLAN ID'
                                        options={availableVlans}
                                        isLoading={vlansLoading}
                                        labelKey={(option) =>
                                          `[${option.vlan_name}] ${option.vlan_id}`
                                        }
                                        isInvalid={!!errors.vlan_id}
                                        onChange={async (suggestion) => {
                                          if (suggestion.length === 0) return;
                                          const item = suggestion[0];
                                          setFieldValue('vlan_id', item.vlan_id);
                                        }}
                                      />
                                      <Form.Control
                                        type='hidden'
                                        name='vlan_id'
                                        value={values.vlan_id}
                                        onChange={handleChange}
                                      />

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.vlan_id}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='priority'>
                                    <Form.Label column sm={4}>
                                      Session Priority
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Select
                                        aria-label='Select the routing priority'
                                        name='priority'
                                        value={values.priority}
                                        isInvalid={!!errors.priority}
                                        onChange={handleChange}
                                      >
                                        <option key='primary' value='primary'>
                                          Primary session
                                        </option>
                                        <option key='redundant' value='redundant'>
                                          First redundant session
                                        </option>
                                        <option key='other' value='other'>
                                          Extra redundancy (last resort)
                                        </option>
                                      </Form.Select>

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.priority}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='redundancy_type'>
                                    <Form.Label column sm={4}>
                                      Device Redundancy
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Select
                                        aria-label='Select the device redundancy type'
                                        name='redundancy_type'
                                        value={values.redundancy_type}
                                        isInvalid={!!errors.redundancy_type}
                                        onChange={handleChange}
                                      >
                                        <option key='none' value='none'>
                                          Single Device (No redundancy)
                                        </option>
                                        <option key='vrrp' value='vrrp'>
                                          Virtual IP (VRRP)
                                        </option>
                                        <option key='dual_session' value='dual_session'>
                                          Multiple BGP Sessions
                                        </option>
                                      </Form.Select>

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.redundancy_type}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className='mb-3' controlId='pop_redundancy'>
                                    <Form.Label column sm={4}>
                                      POP Redundancy
                                    </Form.Label>
                                    <Col sm={8}>
                                      <Form.Select
                                        aria-label='Select the POP redundancy type'
                                        name='pop_redundancy'
                                        value={values.pop_redundancy}
                                        isInvalid={!!errors.pop_redundancy}
                                        onChange={handleChange}
                                      >
                                        <option key='normal' value='normal'>
                                          Normal Network Priority
                                        </option>
                                        <option key='swapped' value='swapped'>
                                          Swap Network Priority
                                        </option>
                                        <option key='twr1' value='twr1'>
                                          Always Prefer TWR1
                                        </option>
                                        <option key='twrb' value='twrb'>
                                          Always Prefer TWR B
                                        </option>
                                      </Form.Select>

                                      <Form.Control.Feedback type='invalid'>
                                        {errors.pop_redundancy}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>

                                  <Button variant='warning' className='me-2' type='submit'>
                                    Update
                                  </Button>
                                  <Button variant='info' value='' onClick={showEdit}>
                                    Cancel
                                  </Button>
                                </Form>
                              )}
                            </Formik>
                          </>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
}

EditTransports.propTypes = {
  site: PropTypes.object,
  hosts: PropTypes.array,
  setHostsChanged: PropTypes.func,
  handleDelete: PropTypes.func,
  getAvailableVlans: PropTypes.func,
  getAvailableInterfaces: PropTypes.func,
  hostsReloading: PropTypes.string,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};
