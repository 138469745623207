// External Imports
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';
import { isValidISODateString } from 'iso-datestring-validator';

// Form
export default function SubscriptionForm({
  subscription,
  isDemo,
  handleForm,
  bandwidthPools,
  ratePlans,
}) {
  // Local Data
  const { Formik } = formik;

  // FORM VALIDATION
  yup.addMethod(yup.date, 'iso8601Format', function (message) {
    return this.test('ISO 8601 Format', message, function (value, context) {
      const originalValue = context.options.originalValue;
      if (typeof originalValue == 'string') {
        return isValidISODateString(originalValue);
      }
      return typeof originalValue == 'object';
    });
  });

  const nameFmt = yup
    .string()
    .matches(/^[a-zA-Z0-9_]+$/, 'Only alphanumeric characters and underscores are allowed.');

  const now = new Date();
  const day1 = new Date();
  day1.setDate(now.getDate() + 1);
  const day7 = new Date();
  day7.setDate(now.getDate() + 8);

  const schema = yup.object().shape({
    pool_name: nameFmt.required(),
    plan_name: nameFmt.required(),
    status: isDemo ? yup.string() : yup.string().oneOf(['active', 'suspended']).required(),
    end_time: isDemo
      ? yup
          .date()
          .iso8601Format('Incorrect Format! Please use the date/time selector!')
          .min(day1.toDateString(), 'Demo must run for min. 1 day')
          .max(day7.toDateString(), 'Demo must run for max. 7 days')
          .required()
      : yup.date(),
    reason: subscription.status !== 'DEFAULT' ? yup.string().required() : yup.string(),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        await handleForm(values, { resetForm });
      }}
      initialValues={{
        pool_name: subscription === undefined ? '' : subscription.pool_name,
        plan_name: subscription === undefined ? '' : subscription.plan_name,
        status:
          subscription === undefined
            ? 'active'
            : subscription.status == 'DEFAULT'
            ? 'active'
            : subscription.status,
        reason: '',
        end_time: isDemo ? day1 : undefined,
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3' controlId='pool_name'>
            <Form.Label column sm={3}>
              Bandwidth Pool
            </Form.Label>
            <Col sm={5}>
              <Form.Select
                aria-label='Select a bandwidth pool'
                name='pool_name'
                value={values.pool_name}
                isInvalid={!!errors.pool_name}
                onChange={handleChange}
              >
                <option key='n/a' value=''>
                  --Select a bandwidth pool--
                </option>

                {bandwidthPools.map((bwPool) => (
                  <option key={bwPool.pool_name} value={bwPool.pool_name}>
                    {bwPool.pool_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.pool_name}</Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted></Form.Text>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='plan_name'>
            <Form.Label column sm={3}>
              Rate Plan
            </Form.Label>
            <Col sm={5}>
              <Form.Select
                aria-label='Select a rate plan'
                name='plan_name'
                value={values.plan_name}
                isInvalid={!!errors.plan_name}
                onChange={handleChange}
              >
                <option key='n/a' value=''>
                  --Select a rate plan--
                </option>

                {ratePlans.map((plan) => (
                  <option key={plan.plan_name} value={plan.plan_name}>
                    {plan.plan_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.plan_name}</Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted></Form.Text>
          </Form.Group>

          {!isDemo && subscription.status !== 'DEFAULT' && (
            <Form.Group as={Row} className='mb-3' controlId='status'>
              <Form.Label column sm={3}>
                Status
              </Form.Label>
              <Col sm={5}>
                <Form.Select
                  aria-label='Select a status'
                  name='status'
                  value={values.status}
                  isInvalid={!!errors.status}
                  onChange={handleChange}
                >
                  <option key='n/a' value=''>
                    --Select a status--
                  </option>
                  <option key='active' value='active'>
                    ACTIVE
                  </option>
                  {status !== 'DEFAULT' && (
                    <option key='suspended' value='suspended'>
                      SUSPENDED
                    </option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.status}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>
          )}

          {isDemo && (
            <Form.Group as={Row} className='mb-3' controlId='end_time'>
              <Form.Label column sm={3}>
                Demo End
              </Form.Label>
              <Col sm={5}>
                <Datetime
                  value={values.end_time}
                  dateFormat='YYYY-MM-DD'
                  timeFormat='HH:mm'
                  onChange={async (ms) => {
                    setFieldValue('end_time', ms); // TODO FORMATTING ?
                  }}
                />
                <Form.Control
                  type='hidden'
                  name='end_time'
                  value={values.end_time}
                  isInvalid={!!errors.end_time}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.end_time}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>
          )}

          {subscription.status !== 'DEFAULT' && (
            <Form.Group as={Row} className='mb-2' controlId='reason'>
              <Form.Label column sm={3}>
                Reason
              </Form.Label>
              <Col sm={5}>
                <Form.Control
                  as='textarea'
                  rows={2}
                  required
                  aria-label='Give a reason'
                  placeholder='Give a reason for this action'
                  name='reason'
                  value={values.reason}
                  onChange={handleChange}
                  isInvalid={!!errors.reason}
                />
                <Form.Control.Feedback type='invalid'>
                  You must enter the reason for this update!
                </Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>
          )}

          {subscription.status === 'DEFAULT' || isDemo ? (
            <Button className='mb-2' variant='success' type='submit'>
              Create
            </Button>
          ) : (
            <Button className='mb-2' variant='warning' type='submit'>
              Update
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}

SubscriptionForm.propTypes = {
  subscription: PropTypes.object,
  //  isCustomerPool: PropTypes.bool,
  isDemo: PropTypes.bool,
  handleForm: PropTypes.func,
  bandwidthPools: PropTypes.array,
  ratePlans: PropTypes.array,
};
