// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

//Components
import PolicyForm from './PolicyForm';

// Hooks
import useToken from '../../utils/useToken';

// Create View
export default function CreatePolicy({ policiesChanged, changePolicies }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Event Handlers
  const createPolicy = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'QoS policy creation has been requested.', severity: 'success' });
          resetForm();
          changePolicies(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create QoS policy! ' + error, severity: 'danger' });
      });
  };

  // Create View
  return (
    <Card>
      <Card.Header>Create QoS Policy</Card.Header>
      <Card.Body>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}
        <PolicyForm handleForm={createPolicy} setError={setError} />
      </Card.Body>
    </Card>
  );
}

CreatePolicy.propTypes = {
  policiesChanged: PropTypes.bool,
  changePolicies: PropTypes.func,
};
