// External Imports
import React, { Fragment, useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//Hooks
import useToken from '../../utils/useToken';

// Main View
export default function DemoReport() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [bwPoolReport, setBwPoolReport] = useState([]);
  const [subsReport, setSubsReport] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getReport = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/reports/demo', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // Sort by date is not possible (need to flatten demos then...)
        const bw = Object.values(data['bandwidth_pools']);
        // TODO: FIX SORT ???
        bw.sort((a, b) => (a['customer_id'] > b['customer_id'] ? 1 : -1));
        bw.sort((a, b) => (a['pool_name'] > b['pool_name'] ? 1 : -1));
        setBwPoolReport(bw);

        const subs = Object.values(data['subscriptions']);
        // TODO: FIX SORT ???
        subs.sort((a, b) => (a['subnet'] > b['subnet'] ? 1 : -1));
        subs.sort((a, b) => (a['site_id'] > b['site_id'] ? 1 : -1));
        subs.sort((a, b) => (a['customer_id'] > b['customer_id'] ? 1 : -1));
        setSubsReport(subs);

        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve report!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getReport();
  }, []);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Active Demos</h1>
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>

      <Card className='mb-3'>
        <Card.Header>Bandwidth Pool Demos</Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Bandwidth Pool</th>
                <th>Demo Status</th>
                <th>Ends</th>
                <th>Downlink MIR</th>
                <th>Uplink MIR</th>
                <th>Uplink</th>
              </tr>
            </thead>
            <tbody>
              {bwPoolReport.map((row) => (
                <Fragment key={row['pool_name']}>
                  {row['demos'].map((demo) => (
                    <tr
                      key={demo['demo_id']}
                      className={
                        demo['status'] == 'RUNNING'
                          ? 'table-success'
                          : demo['status'] == 'FUTURE'
                          ? 'table-info'
                          : 'table-danger'
                      }
                    >
                      <td>
                        <a
                          className='btn btn-primary mb-2'
                          href={'/customers/' + row['customer_id']}
                        >
                          {row['customer_id']}
                        </a>
                        <br />
                        {row['customer_name']}
                      </td>
                      <td>{row['pool_name']}</td>
                      <td>{demo['status']}</td>
                      <td>{demo['end_time']}</td>
                      <td>
                        {demo['downlink_mir']['default'] == demo['downlink_mir']['demo'] ? (
                          <>{demo['downlink_mir']['default'] / 1024} Mbps</>
                        ) : (
                          <>
                            {demo['downlink_mir']['demo'] / 1024} Mbps
                            <br />
                            <s>{demo['downlink_mir']['default'] / 1024} Mbps</s>
                          </>
                        )}
                      </td>
                      <td>
                        {demo['uplink_mir']['default'] == demo['uplink_mir']['demo'] ? (
                          <>{demo['uplink_mir']['default'] / 1024} Mbps</>
                        ) : (
                          <>
                            {demo['uplink_mir']['demo'] / 1024} Mbps
                            <br />
                            <s>{demo['uplink_mir']['default'] / 1024} Mbps</s>
                          </>
                        )}
                      </td>
                      <td>
                        {demo['uplink']['default'] == demo['uplink']['demo'] ? (
                          <>{demo['uplink']['default']}</>
                        ) : (
                          <>
                            {demo['uplink']['demo']}
                            <br />
                            <s>{demo['uplink']['default']}</s>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Subscription Demos</Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Site</th>
                <th>Subnet</th>
                <th>Demo Status</th>
                <th>Ends</th>
                <th>Subscription</th>
                <th>Pool</th>
              </tr>
            </thead>
            <tbody>
              {subsReport.map((row) => (
                <Fragment key={row['subnet']}>
                  {row['demos'].map((demo) => (
                    <tr
                      key={demo['demo_id']}
                      className={
                        demo['status'] == 'RUNNING'
                          ? 'table-success'
                          : demo['status'] == 'FUTURE'
                          ? 'table-info'
                          : 'table-danger'
                      }
                    >
                      <td>
                        <a
                          className='btn btn-primary mb-2'
                          href={'/customers/' + row['customer_id']}
                        >
                          {row['customer_id']}
                        </a>
                        <br />
                        {row['customer_name']}
                      </td>
                      <td>
                        <a className='btn btn-primary mb-2' href={'/sites/' + row['site_id']}>
                          {row['site_id']}
                        </a>
                        <br />
                        {row['site_name']}
                      </td>
                      <td>{row['subnet']}</td>
                      <td>{demo['status']}</td>
                      <td>{demo['end_time']}</td>
                      <td>
                        {demo['plan_name']['default'] == demo['plan_name']['demo'] ? (
                          <>{demo['plan_name']['default']}</>
                        ) : (
                          <>
                            {demo['plan_name']['demo']}
                            <br />
                            <s>{demo['plan_name']['default']}</s>
                          </>
                        )}
                      </td>
                      <td>
                        {demo['pool_name']['default'] == demo['pool_name']['demo'] ? (
                          <>{demo['pool_name']['default']}</>
                        ) : (
                          <>
                            {demo['pool_name']['demo']}
                            <br />
                            <s>{demo['pool_name']['default']}</s>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}
