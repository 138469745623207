// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import Moment from 'moment';

//Hooks
import useToken from '../utils/useToken';

//Main View
export default function EventLog({ show, setEventLog, query }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const [report, setReport] = useState([]);

  const getEventLog = async () => {
    if (show !== true) {
      return;
    }
    if (!isValidToken()) {
      window.location.reload(false);
    }

    query['from_time'] = '2023-12-06T00:00:00Z';

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/reports/audit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(query),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then((text) => {
          throw new Error(text['detail']);
        });
      })
      .then((data) => {
        // Reverse sort
        return data.sort((a, b) => (a.posted_at > b.posted_at ? -1 : 1));
      })
      .then((data) => {
        setReport(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not load Event Log! ' + error, severity: 'warning' });
      });

    setLoading(false);
  };

  useEffect(() => {
    getEventLog();
  }, [show]);

  // Event Handlers
  const hideEventLog = () => setEventLog(false);

  return (
    <Offcanvas show={show} onHide={hideEventLog} placement='bottom' className='h-75'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Event Log</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <Alert variant='warning'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
            <span>Loading Data...</span>
          </Alert>
        ) : (
          <>
            {error.message != '' && (
              <Alert variant={error.severity}>
                <span>{error.message}</span>
              </Alert>
            )}
            <Table striped>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Event</th>
                  <th>Customer</th>
                  <th>BW Pool</th>
                  <th>Site</th>
                  <th>Subnet</th>
                  <th>Old Data</th>
                  <th>New Data</th>
                  <th>Reason</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {report.map((event) => (
                  <tr key={event['posted_at']}>
                    <th>{Moment(event['posted_at']).format('YYYY-MM-DD HH:mm:ss')}</th>
                    <th>{event['event_type']}</th>
                    <td>
                      {event['customer_id'] != '' && (
                        <>
                          [{event['customer_id']}]<br />
                          {event['customer_name']}
                        </>
                      )}
                    </td>
                    <td>{event['bw_pool']}</td>
                    <td>
                      {event['site_id'] != '' && (
                        <>
                          [{event['site_id']}]<br />
                          {event['site_name']}
                        </>
                      )}
                    </td>
                    <td>{event['subnet']}</td>
                    <td>
                      <span style={{ whiteSpace: 'pre-wrap' }}>{event['old_data']}</span>
                    </td>
                    <td>
                      {event['new_data'] == 'No Changes Made' ? (
                        <span className='text-danger'>{event['new_data']}</span>
                      ) : (
                        <span style={{ whiteSpace: 'pre-wrap' }}>{event['new_data']}</span>
                      )}
                    </td>
                    <td>{event['reason']}</td>
                    <td>{event['posted_by']}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

EventLog.propTypes = {
  show: PropTypes.bool,
  setEventLog: PropTypes.func,
  query: PropTypes.object,
};
