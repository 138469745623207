// External Imports
import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Components
import ProcessingAlert from './alerts/ProcessingAlert';
import CreateRatePlan from './bandwidth/CreateRatePlan';
import ListRatePlans from './bandwidth/ListRatePlans';

//Main View
export default function RatePlans() {
  const [ratePlansChanged, changeRatePlans] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Configuration - Bandwidth - Rate Plans</h1>
          {ratePlansChanged && <ProcessingAlert changeFunction={changeRatePlans} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <ListRatePlans ratePlansChanged={ratePlansChanged} changeRatePlans={changeRatePlans} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CreateRatePlan ratePlansChanged={ratePlansChanged} changeRatePlans={changeRatePlans} />
        </Col>
      </Row>
    </>
  );
}
