// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import { Typeahead } from 'react-bootstrap-typeahead';

//Hooks
import useToken from '../../utils/useToken';

// Main View
export default function CustomerAccess({ customer }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [attachedUsers, setAttachedUsers] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);

  const [usersLoading, setLoading] = useState('initial');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getUsers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((users) => {
        setAttachedUsers(
          users.filter((user) => {
            return (
              'customer_ids' in user.attributes &&
              user.attributes.customer_ids.includes(customer.customer_id)
            );
          }),
        );
        setOtherUsers(
          users.filter((user) => {
            return (
              !('customer_ids' in user.attributes) ||
              !user.attributes.customer_ids.includes(customer.customer_id)
            );
          }),
        );
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve users!', severity: 'danger' });
      });

    setLoading('loaded');
  };

  useEffect(() => {
    if (usersLoading != 'loaded') {
      // TODO: NOT ?          // initial -> loaded <-> reload
      getUsers();
    }
  }, [usersLoading]);

  // Event Handlers
  const attach = async (values) => {
    if (values[0] == null) return;
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const params = {
      user_id: values[0]['id'],
      customer_id: customer.customer_id,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          setError({
            message: 'Access grant requested for user.',
            severity: 'success',
          });
          setLoading('reload');
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not attach user! ' + error, severity: 'danger' });
      });
  };

  const unattach = async (event) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    if (!isValidToken()) {
      window.location.reload(false);
    }

    const params = {
      user_id: event.target.value,
      customer_id: customer.customer_id,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v2/keycloak/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          setError({
            message: 'Access grant removal requested for user.',
            severity: 'success',
          });
          setLoading('reload');
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not unattach user! ' + error, severity: 'danger' });
      });
  };

  // View
  return (
    <>
      {usersLoading != 'loaded' && (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Loading Data...</span>
        </Alert>
      )}
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      <Table striped>
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {attachedUsers.map((user) => (
            <tr key={user.username}>
              <td>{user.username}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>
                <Button variant='danger' value={user.id} onClick={unattach}>
                  Remove access
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <hr />

      <Typeahead
        id='LookupUser'
        options={otherUsers}
        minLength={5}
        labelKey={(user) => `${user.username} - ${user.first_name} ${user.last_name}`}
        placeholder='Lookup User (enter min. 5 characters)'
        onChange={attach}
      />
    </>
  );
}

CustomerAccess.propTypes = {
  customer: PropTypes.object,
};
