// External Imports
import React, { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Components
import ReloadAlert from './alerts/ReloadAlert';
import ProcessingAlert from './alerts/ProcessingAlert';
import ListBandwidthPools from './bandwidth/ListBandwidthPools';
import CreateBandwidthPool from './bandwidth/CreateBandwidthPool';

//Hooks
import useToken from '../utils/useToken';
import { fetchBandwidthPools } from '../utils/bandwidth_pools';

//Main View
export default function BandwidthPools() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [bwPoolsChanged, changeBwPools] = useState(false);
  const [bwPools, setBwPools] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getBwPools = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchBandwidthPools(token)
      .then((data) => {
        return Object.values(data).filter((pool) => {
          return pool.customer_id === null;
        });
      })
      .then((data) => {
        setBwPools(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve bandwidth pools! ' + error, severity: 'danger' });
      });
  };

  useEffect(() => {
    getBwPools();
  }, [bwPoolsChanged]);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Configuration - Bandwidth - Bandwidth Pools</h1>
          {bwPoolsChanged && <ProcessingAlert changeFunction={changeBwPools} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Card>
            <Card.Header>Bandwidth Pools</Card.Header>
            <Card.Body>
              <ListBandwidthPools
                bwPools={bwPools}
                upstreamBwPools={bwPools}
                changeBwPools={changeBwPools}
                customerId=''
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Card>
            <Card.Header>Create Bandwidth Pool</Card.Header>
            <Card.Body>
              <CreateBandwidthPool bwPools={bwPools} changeBwPools={changeBwPools} customerId='' />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
