// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Components
import ShowCustomerTree from '../customers/ShowCustomerTree';
import ShowSiteSummary from './ShowSiteSummary';

//Hooks
import useToken from '../../utils/useToken';

//Show Summary View
export default function ShowSite({ siteId }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [site, setSite] = useState({});
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getSite = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/sites/' + siteId, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setSite(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve site!', severity: 'danger' });
      });
  };

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setCustomers(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customers!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getSite();
    getCustomers();
  }, [siteId]);

  // Summary View
  return (
    <>
      {error.message != '' && (
        <Row>
          <Col>
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          </Col>
        </Row>
      )}
      {site.site_id == siteId && (
        <Row>
          <Col sm={6}>
            <ShowSiteSummary site={site} />
            <a className='btn btn-primary' href={'/sites/' + site.site_id}>
              Edit
            </a>
          </Col>
          <Col sm={6}>
            <ShowCustomerTree customerId={site.customer_id} customers={customers} />
          </Col>
        </Row>
      )}
    </>
  );
}

ShowSite.propTypes = {
  siteId: PropTypes.string,
};
