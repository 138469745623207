// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';

//Hooks
import useToken from '../../utils/useToken';

export default function EditCustomer({ customer, customers, changeCustomers }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [asnSuggestion, setAsnSuggestion] = useState([]);
  const [asnList, setAsnList] = useState([]);
  const [deleteOpen, openDelete] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  yup.addMethod(yup.number, 'uniqueASN', function (message) {
    return this.test('Unique ASN', message, function (value) {
      if (value == null) return true; //Not enforcing required
      if (value == customer.asn) return true; // No change
      const match = asnList.filter((asn) => {
        return value == asn;
      });
      return match.length == 0; // Must be unique
    });
  });

  const schema = yup.object().shape({
    customer_id: yup
      .string()
      .matches(/^[A-Z0-9]+$/, 'Only (uppercase) alphanumeric characters are allowed.')
      .min(4)
      .max(10)
      .required(),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphanumeric characters and spaces are allowed.')
      .required(),
    parent_id: yup.string(),
    asn: yup
      .number()
      .nullable(true)
      .min(1)
      .max(65536)
      .uniqueASN(
        'The ASN must be unique (can only be applied on 1 customer OR site). ASN is propagated to child-customers and sites.',
      ),
  });
  const { Formik } = formik;

  const validateASN = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/asn', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then((text) => {
          throw new Error(text['detail']);
        });
      })
      .then((data) => {
        const asnList = Object.values(data);
        setAsnList(asnList);

        const max = asnList.reduce((a, b) => Math.max(a, b), -Infinity);
        setAsnSuggestion(['' + (max + 1)]);
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve ASN list! ' + error, severity: 'danger' });
      });
  };

  useEffect(() => {
    validateASN();
  }, []);

  // Event Handlers
  const handleForm = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    let editCustomer = {};
    for (const [k, v] of Object.entries(values)) {
      if (v != '' && v != null) {
        editCustomer[k] = v;
      }
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers/' + customer.customer_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(editCustomer),
    })
      .then((response) => {
        if (response.ok) {
          changeCustomers(true);
          setError({ message: 'Customer update has been requested.', severity: 'success' });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not change customer! ' + error, severity: 'danger' });
      });
  };

  const showDelete = (event) => {
    openDelete(event.target.value);
  };

  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const customerObj = {
      id: customer.customer_id,
      reason: values.reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers/' + customer.customer_id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(customerObj),
    })
      .then((response) => {
        if (response.ok) {
          openDelete(false);
          changeCustomers(true);
          setError({ message: 'Customer deletion has been requested.', severity: 'success' });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not delete customer! ' + error, severity: 'danger' });
      });

    //TODO: window.location.replace("/customers"); => ON PROCESSINGALERT => callback ?
  };

  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}
      <Formik
        validationSchema={schema}
        onSubmit={handleForm}
        initialValues={{
          customer_id: customer.customer_id,
          name: customer.name,
          parent_id: customer.parent_id == null ? '' : customer.parent_id,
          asn: customer.asn == null ? '' : customer.asn,
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='customerId'>
              <Form.Label column sm={3}>
                Customer ID
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Customer ID'
                  name='customer_id'
                  value={values.customer_id}
                  isInvalid={!!errors.customer_id}
                  onChange={handleChange}
                  disabled
                />
              </Col>
              <Form.Control.Feedback type='invalid'>{errors.customer_id}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='customerName'>
              <Form.Label column sm={3}>
                Customer Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Customer Name'
                  name='name'
                  value={values.name}
                  isInvalid={!!errors.name}
                  onChange={handleChange}
                />
              </Col>
              <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
              <Form.Text muted>
                Format: Customer name only (no abbreviations, project names, site or location
                names). Only letters (, numbers) and spaces
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='parentId'>
              <Form.Label column sm={3}>
                Parent
              </Form.Label>
              <Col sm={6}>
                <Form.Select
                  aria-label='Select a parent customer (optional)'
                  name='parent_id'
                  value={values.parent_id}
                  isInvalid={!!errors.parent_id}
                  onChange={handleChange}
                >
                  <option value=''>--Select a parent customer (optional)--</option>

                  {customers.map((customer) => (
                    <option key={customer.customer_id} value={customer.customer_id}>
                      {customer.prefix} {customer.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Form.Control.Feedback type='invalid'>{errors.parent_id}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='asn'>
              <Form.Label column sm={3}>
                AS Number
              </Form.Label>
              <Col sm={6}>
                <Typeahead
                  id='asn_suggest'
                  name='asn_suggest'
                  defaultInputValue={values.asn}
                  placeholder='Enter AS Number'
                  options={asnSuggestion}
                  allowNew
                  newSelectionPrefix='Define a custom (public) ASN: '
                  isInvalid={!!errors.asn}
                  onChange={async (suggestion) => {
                    if (suggestion.length === 0) return;

                    const item = suggestion[0];
                    if (typeof item === 'string') {
                      setFieldValue('asn', item);
                    } else {
                      setFieldValue('asn', item['label']);
                    }
                  }}
                />
                <Form.Control type='hidden' name='asn' value={values.asn} onChange={handleChange} />
                <Form.Control.Feedback type='invalid'>{errors.asn}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Row className='mb-2'>
              <Col sm={3}>
                <Button variant='warning' type='submit'>
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {customer.sites.length === 0 && customer.children.length === 0 && (
        <Row>
          {deleteOpen === customer.customer_id ? (
            <Col sm={9}>
              <GiveDeleteReason formHandler={handleDelete} cancelHandler={showDelete} />
            </Col>
          ) : (
            <Col sm={3}>
              <Button variant='danger' value={customer.customer_id} onClick={showDelete}>
                Delete
              </Button>
            </Col>
          )}
        </Row>
      )}
    </>
  );
}

EditCustomer.propTypes = {
  customer: PropTypes.object,
  customers: PropTypes.array,
  changeCustomers: PropTypes.func,
};
