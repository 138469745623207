// External Imports
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';

//Edit Table
export default function EditRoutes({
  siteId,
  hosts,
  handleDelete,
  hostsReloading,
  setHostsReloading,
  setErrorUpstream,
}) {
  //Local Data
  const [deleteOpen, openDelete] = useState(false);

  // Event Handlers
  const showDelete = (event) => {
    openDelete(event.target.value);
    setErrorUpstream({ message: '', severity: 'success' });
  };

  const handleLocalDelete = async (values) => {
    const res = await handleDelete(values);
    if (res) {
      openDelete('');
    }
  };

  // View
  return (
    <>
      {hostsReloading == 'route' ? (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Reloading routes...</span>
        </Alert>
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th>Alias</th>
              <th>Route</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {hosts.map((host) => (
              <Fragment key={host.subnet}>
                <tr
                  key={host.subnet + '-view'}
                  className={
                    host.subscription.status == 'active'
                      ? 'table-success'
                      : host.subscription.status == 'suspended'
                      ? 'table-warning'
                      : 'table-danger'
                  }
                >
                  <td>{host.alias}</td>
                  <td>{host.subnet}</td>
                  <td>
                    {host.deleteable && deleteOpen !== host.subnet && (
                      <Button variant='danger' value={host.subnet} onClick={showDelete}>
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
                {host.deleteable && deleteOpen === host.subnet && (
                  <tr key={host.subnet + '-delete'}>
                    <td colSpan='3'>
                      <GiveDeleteReason
                        formHandler={handleLocalDelete}
                        cancelHandler={showDelete}
                        extraValues={{ subnet: host.subnet }}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

EditRoutes.propTypes = {
  siteId: PropTypes.string,
  hosts: PropTypes.array,
  handleDelete: PropTypes.func,
  hostsReloading: PropTypes.string,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};
