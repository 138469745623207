export const fetchEventTypes = async (token) => {
  return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/audit/event_types', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};

export const fetchUsers = async (token) => {
  return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/audit/users', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};
