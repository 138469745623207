// External Imports
import React, { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Typeahead } from 'react-bootstrap-typeahead';

// Components
import ProcessingAlert from './alerts/ProcessingAlert';
import ShowCustomerTree from './customers/ShowCustomerTree';
import CreateCustomer from './customers/CreateCustomer';

//Hooks
import useToken from '../utils/useToken';
import { getCustomerTree, fetchCustomers } from '../utils/customers';

// Main View
export default function Customers() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [customersChanged, changeCustomers] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchCustomers(token)
      .then((customers) => {
        const customerTree = getCustomerTree(customers);
        setCustomers(customerTree);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customers!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getCustomers();
  }, [customersChanged]);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Customers</h1>
          {customersChanged && <ProcessingAlert changeFunction={changeCustomers} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Card>
            <Card.Header>Customers</Card.Header>
            <Card.Body>
              <Typeahead
                id='LookupCustomer'
                options={customers}
                minLength={3}
                labelKey={(option) => `[${option.customer_id}] ${option.prefix} ${option.name}`}
                placeholder='Lookup Customer (enter min. 3 characters)'
                onChange={(selected) => {
                  if (selected[0] != null) {
                    setCustomerId(selected[0].customer_id);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {customerId != '' && (
        <>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Customer: {customerId}</Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <ShowCustomerTree customerId={customerId} customers={customers} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Card>
            <Card.Header>Create New Customer</Card.Header>
            <Card.Body>
              <CreateCustomer
                customers={customers}
                changeCustomers={changeCustomers}
                setCustomerId={setCustomerId}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
