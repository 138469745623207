// External Imports
import React, { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Typeahead } from 'react-bootstrap-typeahead';

//Components
import ProcessingAlert from './alerts/ProcessingAlert';
import ShowSite from './sites/ShowSite';
import CreateSite from './sites/CreateSite';

//Hooks
import useToken from '../utils/useToken';
import { fetchSites } from '../utils/sites';

//Main View
export default function Sites() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [sites, setSites] = useState([]);
  const [siteId, setSiteId] = useState('');
  const [sitesChanged, changeSites] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getSites = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchSites(token)
      .then((data) => {
        setSites(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve sites!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getSites();
  }, [sitesChanged]);

  // Filters
  const filterSites = (option, input) => {
    if (input.text !== null) {
      if (
        option.site_id !== null &&
        option.site_id.toLowerCase().indexOf(input.text.toLowerCase()) !== -1
      )
        return true;
      if (
        option.name !== null &&
        option.name.toLowerCase().indexOf(input.text.toLowerCase()) !== -1
      )
        return true;
      if (
        option.customer_id !== null &&
        option.customer_id.toLowerCase().indexOf(input.text.toLowerCase()) !== -1
      )
        return true;
      if (
        option.customer_name !== null &&
        option.customer_name.toLowerCase().indexOf(input.text.toLowerCase()) !== -1
      )
        return true;
      if (
        option.hosts !== null &&
        JSON.stringify(option.hosts).toLowerCase().indexOf(input.text.toLowerCase()) !== -1
      )
        return true;
    }
    return false;
  };

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Sites</h1>
          {sitesChanged && <ProcessingAlert changeFunction={changeSites} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <Card>
            <Card.Header>Sites</Card.Header>
            <Card.Body>
              <Typeahead
                id='LookupSite'
                options={sites}
                minLength={3}
                filterBy={filterSites}
                labelKey={(option) => `[${option.site_id}] ${option.name}`}
                placeholder='Lookup Site (enter min. 3 characters)'
                onChange={(selected) => {
                  if (selected[0] != null) {
                    setSiteId(selected[0].site_id);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {siteId != '' && (
        <>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Site: {siteId}</Card.Header>
                <Card.Body>
                  <ShowSite siteId={siteId} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Card>
            <Card.Header>Create New Site</Card.Header>
            <Card.Body>
              <CreateSite changeSites={changeSites} sites={sites} setSiteId={setSiteId} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
