// External Imports
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

// Components
import Header from './components/Header';
import Login from './components/Login';

import RatePlans from './components/RatePlans';
import BandwidthPools from './components/BandwidthPools';
import Policies from './components/Policies';
import Users from './components/Users';
import Customers from './components/Customers';
import Customer from './components/Customer';
import Sites from './components/Sites';
import Site from './components/Site';
import AccessDenied from './components/AccessDenied';

import AuditReport from './components/reports/AuditReport';
import BandwidthReport from './components/reports/BandwidthReport';
import DemoReport from './components/reports/DemoReport';
import SuspensionReport from './components/reports/SuspensionReport';

// Hooks
import useToken from './utils/useToken';

// Main application
export default function App() {
  const { token, name, getGroups, setToken } = useToken();

  return (
    <div>
      <Header isLoggedIn={token ? true : false} name={name} groups={getGroups()} />

      <Container>
        {token ? (
          <BrowserRouter>
            <Routes>
              <Route path='/rate_plans' element={<RatePlans />} />
              <Route path='/policies' element={<Policies />} />
              <Route path='/users' element={<Users />} />
              {getGroups().includes('sysadmin') ? (
                <Route path='/bw_pools' element={<BandwidthPools />} />
              ) : (
                <Route path='/bw_pools' element={<AccessDenied />} />
              )}

              <Route path='/sites/:siteId' element={<Site />} />
              <Route path='/sites' element={<Sites />} />
              <Route path='/customers/:customerId' element={<Customer />} />

              <Route path='/reports/audit' element={<AuditReport />} />
              <Route path='/reports/suspended' element={<SuspensionReport />} />
              <Route path='/reports/demo' element={<DemoReport />} />
              {getGroups().includes('sysadmin') ? (
                <Route path='/reports/bandwidth' element={<BandwidthReport />} />
              ) : (
                <Route path='/reports/bandwidth' element={<AccessDenied />} />
              )}

              <Route path='*' element={<Customers />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <Login setToken={setToken} />
        )}
      </Container>
    </div>
  );
}
