// External Imports
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';
import { isValidISODateString } from 'iso-datestring-validator';

// Form
export default function BandwidthPoolForm({
  pool,
  isCustomerPool,
  isDemo = false,
  bwPools,
  handleForm,
}) {
  // Local Data
  const { Formik } = formik;

  // FORM VALIDATION
  const validateMir = (values, props) => {
    const errors = {};

    if (values.dynamic === true) {
      if (values.downlink_mir != '') {
        errors.downlink_mir = 'Dynamic MIR is enabled!';
      }

      if (values.uplink_mir != '') {
        errors.uplink_mir = 'Dynamic MIR is enabled!';
      }
    }

    return errors;
  };

  yup.addMethod(yup.number, 'fibreBandwidth', function (message) {
    return this.test('Fibre Bandwidth', message, function (value) {
      if (value == null) return true; //Not enforcing required and > 0
      if (value % 250 == 0) return true;
      if (value <= 1000 && value % 100 == 0) return true;
      if (value <= 500 && value % 50 == 0) return true;
      if (value <= 250 && value % 25 == 0) return true;
      if (value <= 150 && value % 10 == 0) return true;
      if (value <= 50 && value % 5 == 0) return true;
      if (value <= 20 && value % 2 == 0) return true;
      if (value <= 10 && value % 1 == 0) return true;
      return false;
    });
  });

  yup.addMethod(yup.date, 'iso8601Format', function (message) {
    return this.test('ISO 8601 Format', message, function (value, context) {
      const originalValue = context.options.originalValue;
      if (typeof originalValue == 'string') {
        return isValidISODateString(originalValue);
      }
      return typeof originalValue == 'object';
    });
  });

  const nameFmt = yup
    .string()
    .matches(
      /^[A-Z0-9_]+$/,
      'Only (uppercase) alphanumeric characters and underscores are allowed.',
    );
  const descFmt = yup
    .string()
    .matches(
      /^[a-zA-Z0-9: \-]+$/,
      'Only alphanumeric characters, spaces, colons (:) and dashes (-) are allowed.',
    );
  const bwFmt = yup
    .number()
    .fibreBandwidth('Please follow business guidelines on available bandwidth options');
  const mirFmt = bwFmt.min(1);

  const now = new Date();
  const day1 = new Date();
  day1.setDate(now.getDate() + 1);
  const day7 = new Date();
  day7.setDate(now.getDate() + 8);

  const schema = yup.object().shape({
    pool_name: pool === null ? nameFmt.required() : nameFmt,
    pool_description: isDemo ? descFmt : descFmt.required(),
    uplink: isCustomerPool ? yup.string().required() : yup.string(),
    uplink_mir: isCustomerPool ? mirFmt.required() : mirFmt,
    downlink_mir: isCustomerPool ? mirFmt.required() : mirFmt,
    uplink_cir: bwFmt,
    downlink_cir: bwFmt,
    reason: pool !== null ? yup.string().required() : yup.string(),
    end_time: isDemo
      ? yup
          .date()
          .iso8601Format('Incorrect Format! Please use the date/time selector!')
          .min(day1.toDateString(), 'Demo must run for min. 1 day')
          .max(day7.toDateString(), 'Demo must run for max. 7 days')
          .required()
      : yup.date(),
  });

  return (
    <Formik
      validationSchema={schema}
      validate={validateMir}
      onSubmit={async (values, { resetForm }) => {
        await handleForm(values, { resetForm });
      }}
      initialValues={{
        pool_name: '',
        pool_description:
          pool === null || pool.pool_description === null ? '' : pool.pool_description,
        uplink: pool === null || pool.uplink === null ? '' : pool.uplink,
        uplink_cir: pool === null ? '' : pool.uplink_cir / 1024,
        downlink_cir: pool === null ? '' : pool.downlink_cir / 1024,
        uplink_mir: pool === null ? '' : pool.uplink_mir > 0 ? pool.uplink_mir / 1024 : '',
        downlink_mir: pool === null ? '' : pool.downlink_mir > 0 ? pool.downlink_mir / 1024 : '',
        dynamic: pool === null ? false : pool.uplink_mir == 'dynamic',
        reason: '',
        end_time: day1,
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {pool === null && (
            <Form.Group as={Row} className='mb-3' controlId='pool_name'>
              <Form.Label column sm={2}>
                Pool Name
              </Form.Label>
              <Col sm={2}>
                <Form.Control
                  type='text'
                  placeholder='Pool Name'
                  name='pool_name'
                  value={values.pool_name}
                  isInvalid={!!errors.pool_name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.pool_name}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                Format: Preferably customer abbreviation, (optionally) followed by pool name. Only
                uppercase letters and underscores allowed.
              </Form.Text>
            </Form.Group>
          )}

          {!isDemo && (
            <Form.Group as={Row} className='mb-3' controlId='pool_description'>
              <Form.Label column sm={2}>
                Pool Description
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type='text'
                  placeholder='Pool Description'
                  name='pool_description'
                  value={values.pool_description}
                  isInvalid={!!errors.pool_description}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.pool_description}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className='mb-3' controlId='uplink'>
            <Form.Label column sm={2}>
              Uplink Pool
            </Form.Label>
            <Col sm={4}>
              <Form.Select
                aria-label='Select a bandwidth pool'
                name='uplink'
                value={values.uplink}
                isInvalid={!!errors.uplink}
                onChange={handleChange}
              >
                <option value=''>--Select a bandwidth pool--</option>

                {bwPools.map((bwPool) => (
                  <option key={bwPool.pool_name} value={bwPool.pool_name}>
                    {bwPool.pool_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.uplink}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          {values.dynamic ? (
            <Form.Group as={Row} className='mb-3' controlId='dynamic'>
              <Form.Label column sm={2}>
                Dynamic MIR
              </Form.Label>
              <Col sm={2}>
                <Form.Check
                  type='switch'
                  name='dynamic'
                  placeholder='Dynamic MIR'
                  checked
                  disabled
                />
              </Col>
            </Form.Group>
          ) : (
            <>
              <Form.Group as={Row} className='mb-3' controlId='uplink_mir'>
                <Form.Label column sm={2}>
                  Uplink MIR (Mbps)
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type='text'
                    placeholder='Uplink MIR (Mbps)'
                    name='uplink_mir'
                    value={values.uplink_mir}
                    isInvalid={!!errors.uplink_mir}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.uplink_mir}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3' controlId='downlink_mir'>
                <Form.Label column sm={2}>
                  Downlink MIR (Mbps)
                </Form.Label>
                <Col sm={2}>
                  <Form.Control
                    type='text'
                    placeholder='Downlink MIR (Mbps)'
                    name='downlink_mir'
                    value={values.downlink_mir}
                    isInvalid={!!errors.downlink_mir}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.downlink_mir}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          )}

          {/*
        <Form.Group as={Row} className='mb-3' controlId='uplink_cir'>
          <Form.Label column sm={2}>
            Uplink CIR (Mbps)
          </Form.Label>
          <Col sm={2}>
            <Form.Control
              type='text'
              placeholder='Uplink CIR (Mbps)'
              name='uplink_cir'
              value={values.uplink_cir}
              isInvalid={!!errors.uplink_cir}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>{errors.uplink_cir}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mb-3' controlId='downlink_cir'>
          <Form.Label column sm={2}>
            Downlink CIR (Mbps)
          </Form.Label>
          <Col sm={2}>
            <Form.Control
              type='text'
              placeholder='Downlink CIR (Mbps)'
              name='downlink_cir'
              value={values.downlink_cir}
              isInvalid={!!errors.downlink_cir}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.downlink_cir}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        */}

          {isDemo && (
            <Form.Group as={Row} className='mb-3' controlId='end_time'>
              <Form.Label column sm={2}>
                Demo End
              </Form.Label>
              <Col sm={2}>
                <Datetime
                  value={values.end_time}
                  dateFormat='YYYY-MM-DD'
                  timeFormat='HH:mm'
                  onChange={async (ms) => {
                    setFieldValue('end_time', ms); // TODO FORMATTING ?
                  }}
                />
                <Form.Control
                  type='hidden'
                  name='end_time'
                  value={values.end_time}
                  isInvalid={!!errors.end_time}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.end_time}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}

          {pool !== null && (
            <Col sm={3}>
              <Form.Group className='mb-2' controlId='reason'>
                <Form.Control
                  as='textarea'
                  rows={2}
                  required
                  aria-label='Give a reason'
                  placeholder='Give a reason for this action'
                  name='reason'
                  value={values.reason}
                  onChange={handleChange}
                  isInvalid={!!errors.reason}
                />
                <Form.Control.Feedback type='invalid'>
                  You must enter the reason for this update!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}

          {pool === null && isCustomerPool === false && (
            <Form.Group as={Row} className='mb-3' controlId='dynamic'>
              <Form.Label column sm={2}>
                Dynamic MIR
              </Form.Label>
              <Col sm={2}>
                <Form.Check
                  type='switch'
                  name='dynamic'
                  placeholder='Dynamic MIR'
                  value={values.dynamic}
                  isInvalid={!!errors.dynamic}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.dynamic}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                MIR will be controlled by external scripts.
              </Form.Text>
            </Form.Group>
          )}

          {pool === null || isDemo ? (
            <Button className='mb-2' variant='success' type='submit'>
              Create
            </Button>
          ) : (
            <Button className='mb-2' variant='warning' type='submit'>
              Update
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}

BandwidthPoolForm.propTypes = {
  pool: PropTypes.object,
  isCustomerPool: PropTypes.bool,
  isDemo: PropTypes.bool,
  bwPools: PropTypes.array,
  handleForm: PropTypes.func,
};
