// External Imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

//Components
import EventLog from './EventLog';

import ProcessingAlert from './alerts/ProcessingAlert';

import ShowSiteSummary from './sites/ShowSiteSummary';

import EditSite from './sites/EditSite';
import EditHosts from './sites/EditHosts';
import EditSubscriptions from './sites/EditSubscriptions';

import ListAttachedQosPolicies from './qos/ListAttachedQosPolicies';

//Hooks
import useToken from '../utils/useToken';

//Main View
export default function Site() {
  const { token, isValidToken } = useToken();
  const params = useParams();

  // Local Data
  const [site, setSite] = useState({});
  const [allHosts, setAllHosts] = useState([]);

  const [siteChanged, changeSite] = useState(false);
  const [hostsChanged, setHostsChanged] = useState(false);

  const [hostsReloading, setHostsReloading] = useState('');

  const [eventLogVisible, setEventLog] = useState(false);
  const [eventLogQuery] = useState({
    site_id: params.siteId,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getSite = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/sites/' + params.siteId, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setSite(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve site!', severity: 'danger' });
      });
  };

  const getHost = async (ip) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/' + ip, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: '', severity: 'success' });
          return response.json();
        }
        throw response;
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve host!', severity: 'danger' });
      });

    return null;
  };

  const getSubscription = async (ip) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          setError({ message: '', severity: 'success' });
          return {
            status: 'DEFAULT',
          };
        } else if (response.status === 200) {
          setError({ message: '', severity: 'success' });
          return response.json();
        }
        throw response;
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve subscription!', severity: 'danger' });
      });

    return null;
  };

  const getHosts = async () => {
    if (site.hosts === undefined) return;

    const hostData = await Promise.all(
      site.hosts.map(async (subnet) => {
        const ip = subnet.split('/')[0];
        const host = await getHost(ip);
        const subs = await getSubscription(ip);
        return {
          ...host,
          subscription: subs,
        };
      }),
    );

    const transports = hostData.filter((host) => {
      return host.host_type === 'transport';
    }).length;
    const routes = hostData.filter((host) => {
      return host.host_type === 'route';
    }).length;
    const hosts = hostData.map((subnet) => {
      let deleteable = false;
      if (subnet.host_type === 'transport') {
        if (transports > 1) {
          deleteable = subnet.subscription.status === 'DEFAULT';
        } else {
          deleteable = subnet.subscription.status === 'DEFAULT' && routes == 0;
        }
      }
      if (subnet.host_type === 'route') {
        deleteable = subnet.subscription.status === 'DEFAULT';
      }

      let deleteable2 =
        subnet.subscription.status === 'DEFAULT' &&
        (subnet.host_type == 'route' || transports > 1 || routes == 0);
      if (deleteable !== deleteable2) {
        console.log('Mistake found in delete button logic!');
      }

      return {
        ...subnet,
        deleteable: deleteable,
      };
    });

    setAllHosts(hosts);
    setLoading(false);
    if (!hostsChanged) setHostsReloading('');
  };

  useEffect(() => {
    getSite();
  }, [siteChanged, hostsChanged]);

  useEffect(() => {
    getHosts();
  }, [site]);

  // Event Handlers
  const showEventLog = () => setEventLog(true);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Site: {params.siteId}</h1>
          {siteChanged && <ProcessingAlert changeFunction={changeSite} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      {site.site_id == params.siteId && (
        <>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Site Summary</Card.Header>
                <Card.Body>
                  <ShowSiteSummary site={site} />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col sm={8}>Edit Site</Col>
                    <Col sm={4}>
                      <Button variant='info' size='sm' onClick={showEventLog}>
                        Event Log
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <EditSite site={site} changeSite={changeSite} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {loading ? (
            <Row className='mb-3'>
              <Col>
                <Alert variant='warning'>
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                  <span>Loading hosts and subscriptions...</span>
                </Alert>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>{hostsChanged && <ProcessingAlert changeFunction={setHostsChanged} />}</Col>
              </Row>
              <Row className='mb-3'>
                <Col sm={6}>
                  <EditHosts
                    type='transport'
                    site={site}
                    allHosts={allHosts}
                    setHostsChanged={setHostsChanged}
                    hostsReloading={hostsReloading}
                    setHostsReloading={setHostsReloading}
                  />
                </Col>
                <Col sm={6}>
                  <EditHosts
                    type='route'
                    site={site}
                    allHosts={allHosts}
                    setHostsChanged={setHostsChanged}
                    hostsReloading={hostsReloading}
                    setHostsReloading={setHostsReloading}
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Card>
                    <Card.Header>Subscriptions</Card.Header>
                    <Card.Body>
                      <EditSubscriptions
                        site={site}
                        allHosts={allHosts}
                        setHostsChanged={setHostsChanged}
                        hostsReloading={hostsReloading}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <Card>
                <Card.Header>Attached QoS Policies</Card.Header>
                <Card.Body>
                  <ListAttachedQosPolicies site={site} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <EventLog show={eventLogVisible} setEventLog={setEventLog} query={eventLogQuery} />
        </>
      )}
    </>
  );
}
