// External Imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';

import BandwidthPoolForm from './BandwidthPoolForm';

//Hooks
import useToken from '../../utils/useToken';

//List View
export default function ListBandwidthPools({
  bwPools,
  upstreamBwPools,
  changeBwPools,
  customerId,
}) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [deletePool, setDeletePool] = useState('');
  const [editVisible, setEditVisible] = useState('');
  const [demoVisible, setDemoVisible] = useState('');

  const [error, setError] = useState({ message: '', severity: 'primary' });

  const { Formik } = formik;
  const now = new Date();
  const day1 = new Date();
  day1.setDate(now.getDate() + 1);
  const day7 = new Date();
  day7.setDate(now.getDate() + 4);

  const schema = yup.object().shape({
    demo_id: yup.string().required(),
    end_time: yup
      .date()
      .min(now.toDateString(), 'End time must be in the future')
      .max(day7.toDateString(), 'Demo can only be extended by max. 3 days')
      .required(),
  });

  // Calculated Data
  const add_children = (hierarchy, pool_name, level) => {
    level++;
    for (const i in bwPools) {
      if (bwPools[i].uplink === pool_name) {
        const pool = bwPools[i];
        pool['display_name'] = '--'.repeat(level) + ' ' + pool['pool_name'];
        hierarchy.push(pool);
        add_children(hierarchy, pool.pool_name, level);
      }
    }
    return hierarchy;
  };

  const getHierarchy = () => {
    let hierarchy = [];

    if (customerId === '') {
      // System
      for (const i in bwPools) {
        if (bwPools[i].uplink === '' || bwPools[i].uplink === null) {
          const pool = bwPools[i];
          pool['display_name'] = pool['pool_name'];
          hierarchy.push(pool);
          add_children(hierarchy, bwPools[i].pool_name, 0);
        }
      }
    } else {
      // Customer specific
      let myPools = [];
      for (const i in bwPools) {
        myPools.push(bwPools[i]['pool_name']);
      }

      for (const i in bwPools) {
        if (!myPools.includes(bwPools[i].uplink)) {
          const pool = bwPools[i];
          pool['display_name'] = pool['pool_name'];
          hierarchy.push(pool);
          add_children(hierarchy, bwPools[i].pool_name, 0);
        }
      }
    }
    return hierarchy;
  };
  const hierarchy = getHierarchy();

  // Event Handlers
  const showDelete = (event) => {
    setDeletePool(event.target.value);
  };

  const showEdit = (event) => {
    setDemoVisible('');
    setEditVisible(event.target.value);
  };

  const showDemo = (event) => {
    setEditVisible('');
    setDemoVisible(event.target.value);
  };

  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const poolObj = {
      id: deletePool,
      reason: values.reason,
    };
    if (customerId != null && customerId != '') {
      poolObj['customer_id'] = customerId;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools/' + deletePool, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(poolObj),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Bandwidth Pool deletion has been requested.', severity: 'success' });
          setDeletePool('');
          changeBwPools(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not delete bandwidth pool! ' + error, severity: 'danger' });
      });
  };

  const validatePooledBandwidth = async (pool_name, uplink_mir, downlink_mir) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const validationData = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pool_validation/' + pool_name,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          setError({ message: '', severity: 'success' });
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        json['uplink_mir'] = uplink_mir;
        json['downlink_mir'] = downlink_mir;
        return json;
      })
      .catch((error) => {
        setError({
          message: 'Could not fetch bandwidth pool validation data!',
          severity: 'danger',
        });
        return false;
      });
    if (validationData === false) return false;
    if (validationData['customer_id'] === null) return true;

    const validation = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pool_validation/' + pool_name,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(validationData),
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        if (!json['result']) {
          setError({ message: json['reason'], severity: 'warning' });
        }
        return json['result'];
      })
      .catch((error) => {
        setError({ message: 'Could not validate bandwidth pool!', severity: 'danger' });
        return false;
      });

    return validation;
  };

  const handleUpdate = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const validated = await validatePooledBandwidth(
      editVisible,
      values.uplink_mir * 1024,
      values.downlink_mir * 1024,
    );
    if (!validated) {
      return;
    }

    const poolObj = {
      pool_name: editVisible,
      pool_description: values.pool_description,
      uplink: values.uplink,
      uplink_mir: values.uplink_mir * 1024,
      downlink_mir: values.downlink_mir * 1024,
      reason: values.reason,
    };
    if (customerId != null && customerId != '') {
      poolObj['customer_id'] = customerId;
    }
    if (values.dynamic === true) {
      poolObj['uplink_mir'] = 'dynamic';
      poolObj['downlink_mir'] = 'dynamic';
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools/' + editVisible, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(poolObj),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Bandwidth Pool update has been requested.', severity: 'success' });
          setEditVisible('');
          changeBwPools(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update bandwidth pool! ' + error, severity: 'danger' });
      });
  };

  const createDemo = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const validated = await validatePooledBandwidth(
      demoVisible,
      values.uplink_mir * 1024,
      values.downlink_mir * 1024,
    );
    if (!validated) {
      return;
    }

    const demoObj = {
      pool_name: demoVisible,
      uplink: values.uplink,
      uplink_mir: values.uplink_mir * 1024,
      downlink_mir: values.downlink_mir * 1024,
      end_time: values.end_time,
      reason: values.reason,
    };
    if (customerId != null && customerId != '') {
      demoObj['customer_id'] = customerId;
    }

    await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools/' + demoVisible + '/demo',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(demoObj),
      },
    )
      .then((response) => {
        if (response.ok) {
          setDemoVisible('');
          changeBwPools(true);
          setError({
            message: 'Bandwidth Pool demo creation has been requested.',
            severity: 'success',
          });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create bandwidth pool demo! ' + error, severity: 'danger' });
      });
  };

  const updateDemo = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const demoObj = {
      pool_name: demoVisible, //OPTIONAL
      demo_id: values.demo_id,
      end_time: values.end_time,
    };
    if (customerId != null && customerId != '') {
      demoObj['customer_id'] = customerId;
    }

    await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools/' + demoVisible + '/demo',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(demoObj),
      },
    )
      .then((response) => {
        if (response.ok) {
          setDemoVisible('');
          changeBwPools(true);
          setError({
            message: 'Bandwidth Pool demo update has been requested.',
            severity: 'success',
          });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update bandwidth pool demo! ' + error, severity: 'danger' });
      });
  };

  // View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>MIR</th>
            {/*<th>CIR</th>*/}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {hierarchy.map((pool) => (
            <Fragment key={pool.pool_name}>
              <tr>
                <th>{pool.display_name}</th>
                <td>{pool.pool_description}</td>
                {pool.uplink_mir === 'dynamic' ? (
                  <td>DYNAMIC</td>
                ) : (
                  <>
                    {pool.uplink_mir > 0 || pool.downlink_mir > 0 ? (
                      <td>
                        {pool.uplink_mir / 1024} / {pool.downlink_mir / 1024} Mbps
                      </td>
                    ) : (
                      <td>NOT SET</td>
                    )}
                  </>
                )}
                {/*
                {pool.uplink_cir > 0 || pool.downlink_cir > 0 ? (
                  <td>
                    {pool.uplink_cir / 1024} / {pool.downlink_cir / 1024} Mbps
                  </td>
                ) : (
                  <td>NOT SET</td>
                )}
*/}
                <td>
                  {editVisible === pool.pool_name ? (
                    <Button as={Col} className='me-2' variant='info' onClick={showEdit}>
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant='primary'
                      className='me-2'
                      value={pool.pool_name}
                      onClick={showEdit}
                    >
                      Edit
                    </Button>
                  )}
                  {customerId !== '' && (
                    <>
                      {demoVisible === pool.pool_name ? (
                        <Button as={Col} className='me-2' variant='info' onClick={showDemo}>
                          Cancel
                        </Button>
                      ) : (
                        <Button
                          variant='primary'
                          className='me-2'
                          value={pool.pool_name}
                          onClick={showDemo}
                        >
                          Demo
                        </Button>
                      )}
                    </>
                  )}
                  {pool.subscriptions.length === 0 && Object.keys(pool.children).length === 0 && (
                    <>
                      {deletePool === pool.pool_name ? (
                        <GiveDeleteReason formHandler={handleDelete} cancelHandler={showDelete} />
                      ) : (
                        <Button variant='warning' value={pool.pool_name} onClick={showDelete}>
                          Delete
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
              {editVisible === pool.pool_name && (
                <tr>
                  <td colSpan={4}>
                    <BandwidthPoolForm
                      isCustomerPool={customerId !== ''}
                      pool={pool}
                      bwPools={upstreamBwPools.filter((upstream) => {
                        return upstream.pool_name !== pool.pool_name;
                      })}
                      handleForm={handleUpdate}
                    />
                  </td>
                </tr>
              )}
              {demoVisible === pool.pool_name && (
                <>
                  {Object.keys(pool.demos).map((demo_id) => (
                    <Fragment key={demo_id}>
                      {pool.demos[demo_id].status != 'ARCHIVED' && (
                        <tr
                          className={
                            pool.demos[demo_id].status == 'RUNNING'
                              ? 'table-success'
                              : pool.demos[demo_id].status == 'EXPIRED'
                              ? 'table-danger'
                              : 'table-info'
                          }
                        >
                          <th>{pool.demos[demo_id].status}</th>
                          <td>--{pool.demos[demo_id].uplink}</td>
                          <td>
                            {pool.demos[demo_id].downlink_mir / 1024} Mbps /{' '}
                            {pool.demos[demo_id].uplink_mir / 1024} Mbps
                          </td>
                          <td>
                            {pool.demos[demo_id].status == 'EXPIRED' ? (
                              <>{pool.demos[demo_id].end_time}</>
                            ) : (
                              <Formik
                                validationSchema={schema}
                                onSubmit={async (values, { resetForm }) => {
                                  await updateDemo(values, { resetForm });
                                }}
                                initialValues={{
                                  demo_id: demo_id,
                                  end_time: pool.demos[demo_id].end_time,
                                }}
                              >
                                {({
                                  handleSubmit,
                                  handleChange,
                                  setFieldValue,
                                  values,
                                  touched,
                                  errors,
                                }) => (
                                  <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId='demo_id'>
                                      <input type='hidden' name='demo_id' value={values.demo_id} />
                                    </Form.Group>

                                    <Form.Group as={Row} className='mb-3' controlId='end_time'>
                                      <Col sm={6}>
                                        <Datetime
                                          value={values.end_time}
                                          dateFormat='YYYY-MM-DD'
                                          timeFormat='HH:mm'
                                          onChange={async (ms) => {
                                            setFieldValue('end_time', ms); // TODO FORMATTING ?
                                          }}
                                        />
                                        <Form.Control
                                          type='hidden'
                                          name='end_time'
                                          value={values.end_time}
                                          isInvalid={!!errors.end_time}
                                          onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                          {errors.end_time}
                                        </Form.Control.Feedback>
                                      </Col>

                                      <Col>
                                        <Button className='mb-2' variant='warning' type='submit'>
                                          Update
                                        </Button>
                                      </Col>
                                    </Form.Group>
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}

                  <tr>
                    <td colSpan={4}>
                      <BandwidthPoolForm
                        isCustomerPool={customerId !== ''}
                        pool={pool}
                        isDemo={true}
                        bwPools={upstreamBwPools.filter((upstream) => {
                          return upstream.pool_name !== pool.pool_name;
                        })}
                        handleForm={createDemo}
                      />
                    </td>
                  </tr>
                </>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
}

ListBandwidthPools.propTypes = {
  bwPools: PropTypes.array,
  upstreamBwPools: PropTypes.array,
  changeBwPools: PropTypes.func,
  customerId: PropTypes.string,
};
