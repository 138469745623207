// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';
import { getCustomerTree, fetchCustomers } from '../../utils/customers';
import { fetchSites } from '../../utils/sites';

//Create View
export default function CreateSite({ changeSites, sites, setSiteId }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [customers, setCustomers] = useState([]);
  const [siteIdSuggestion, setSiteIdSuggestion] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchCustomers(token)
      .then((data) => {
        const customerTree = getCustomerTree(data);
        setCustomers(customerTree);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customers!', severity: 'danger' });
      });
  };

  const suggestSiteIDs = () => {
    let ranges = {};
    sites.map((site) => {
      // If not ending in number: ignore
      const match = site.site_id.match(/([A-Z_]*)([0-9]+)$/);
      if (match) {
        const prefix = match[1];
        const number = parseInt(match[2]);

        if (prefix in ranges) {
          if (number > ranges[prefix]) {
            ranges[prefix] = number;
          }
        } else {
          ranges[prefix] = number;
        }
      }
    });

    const suggest = Object.keys(ranges).map((prefix) => {
      const nextID = ranges[prefix] + 1;
      return prefix + (nextID < 10 ? '00' : nextID < 100 ? '0' : '') + nextID;
    });

    setSiteIdSuggestion(suggest);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    suggestSiteIDs();
  }, [sites]);

  const schema = yup.object().shape({
    site_id: yup
      .string()
      .matches(/^[A-Z0-9]+$/, 'Only (uppercase) alphanumeric characters are allowed.')
      .min(5)
      .max(15)
      .required(),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9 \-]+$/, 'Only alphanumeric characters, dashes and spaces are allowed.') // dashes ?
      .required(),
    customer_id: yup
      .string()
      .matches(/^[A-Z0-9]+$/, 'Only alphanumeric characters are allowed.')
      .required(),
  });
  const { Formik } = formik;

  // Event Handlers
  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    let newSite = {};
    for (const [k, v] of Object.entries(values)) {
      if (v != '') {
        newSite[k] = v;
      }
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/sites', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newSite),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Site creation has been requested.', severity: 'success' });
          resetForm();
          changeSites(true);
          setSiteId(values.site_id);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create site! ' + error, severity: 'danger' });
      });
  };

  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}
      <Formik
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          await handleForm(values, { resetForm });
        }}
        initialValues={{
          site_id: '',
          name: '',
          customer_id: '',
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='site_id'>
              <Form.Label column sm={2}>
                Site ID
              </Form.Label>
              <Col sm={3}>
                <Typeahead
                  id='site_id_suggest'
                  name='site_id_suggest'
                  placeholder='Enter Site ID'
                  options={siteIdSuggestion}
                  allowNew
                  newSelectionPrefix='Add a new ID range: '
                  isInvalid={!!errors.site_id}
                  onChange={async (suggestion) => {
                    if (suggestion.length === 0) return;

                    const item = suggestion[0];
                    if (typeof item === 'string') {
                      setFieldValue('site_id', item);
                    } else {
                      setFieldValue('site_id', item['label']);
                    }
                  }}
                />
                <Form.Control
                  type='hidden'
                  name='site_id'
                  value={values.site_id}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.site_id}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='name'>
              <Form.Label column sm={2}>
                Site Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Site Name'
                  name='name'
                  value={values.name}
                  isInvalid={!!errors.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                Format: (Optionally) customer name/abbreviation, site name, (optionally) site type
                (residence/office/...), (optionally) location Only spaces and dashes (-) are
                allowed.
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='customer_id'>
              <Form.Label column sm={2}>
                Customer
              </Form.Label>
              <Col sm={6}>
                <Form.Select
                  aria-label='Select the customer'
                  name='customer_id'
                  value={values.customer_id}
                  isInvalid={!!errors.customer_id}
                  onChange={handleChange}
                >
                  <option value=''>--Select a customer--</option>

                  {customers.map((customer) => (
                    <option key={customer.customer_id} value={customer.customer_id}>
                      {customer.prefix} {customer.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.customer_id}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Button variant='success' type='submit'>
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

CreateSite.propTypes = {
  changeSites: PropTypes.func,
  sites: PropTypes.arr,
  setSiteId: PropTypes.func,
};
