// External Imports
import React, { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//Hooks
import useToken from '../../utils/useToken';

// Main View
export default function SuspensionReport() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [report, setReport] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getReport = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/reports/suspended', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        data.sort((a, b) => {
          if (a['days_suspended'] == b['days_suspended']) {
            if (a['customer_id'] == b['customer_id']) {
              if (a['site_id'] == b['site_id']) {
                if (a['subnet'] == b['subnet']) {
                  return 0;
                } else return a['subnet'] > b['subnet'] ? 1 : -1;
              } else return a['site_id'] > b['site_id'] ? 1 : -1;
            } else return a['customer_id'] > b['customer_id'] ? 1 : -1;
          } else {
            if (b['days_suspended'] == 'UNKNOWN') return -1;
            if (a['days_suspended'] == 'UNKNOWN') return 1;
            return b['days_suspended'] - a['days_suspended'];
          }
        });
        setReport(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve report!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getReport();
  }, []);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Suspended Sites</h1>
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>

      <Card>
        <Card.Header>Suspension Report</Card.Header>
        <Card.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Site</th>
                <th>Subnet</th>
                <th>Subscription</th>
                <th>Days Suspended</th>
              </tr>
            </thead>
            <tbody>
              {report.map((row) => (
                <tr key={row['subnet']}>
                  <td>
                    <a className='btn btn-primary mb-2' href={'/customers/' + row['customer_id']}>
                      {row['customer_id']}
                    </a>
                    <br />
                    {row['customer_name']}
                  </td>
                  <td>
                    <a className='btn btn-primary mb-2' href={'/sites/' + row['site_id']}>
                      {row['site_id']}
                    </a>
                    <br />
                    {row['site_name']}
                  </td>
                  <td>{row['subnet']}</td>
                  <td>{row['subscription']}</td>
                  <td
                    className={
                      row['days_suspended'] == 'UNKNOWN'
                        ? 'table-info'
                        : row['days_suspended'] > 30
                        ? 'table-danger'
                        : row['days_suspended'] > 7
                        ? 'table-warning'
                        : 'table-success'
                    }
                  >
                    {row['days_suspended']}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}
