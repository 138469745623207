// External Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

// Create View
export default function CreateCustomer({ customers, changeCustomers, setCustomerId }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const schema = yup.object().shape({
    customer_id: yup
      .string()
      .matches(/^[A-Z0-9]+$/, 'Only (uppercase) alphanumeric characters are allowed.')
      .min(4)
      .max(10)
      .required(),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]+$/, 'Only alphanumeric characters and spaces are allowed.')
      .required(),
    parent_id: yup.string(),
  });
  const { Formik } = formik;

  // Event Handlers
  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    let newCustomer = {};
    for (const [k, v] of Object.entries(values)) {
      if (v != '') {
        newCustomer[k] = v;
      }
    }

    fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newCustomer),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Customer creation has been requested.', severity: 'success' });
          resetForm();
          changeCustomers(true);
          setCustomerId(values.customer_id);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create customer! ' + error, severity: 'danger' });
      });
  };

  // Create View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}
      <Formik
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          await handleForm(values, { resetForm });
        }}
        initialValues={{
          customer_id: '',
          name: '',
          parent_id: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='customer_id'>
              <Form.Label column sm={2}>
                Customer ID
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  type='text'
                  placeholder='Enter Customer ID'
                  name='customer_id'
                  value={values.customer_id}
                  isInvalid={!!errors.customer_id}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.customer_id}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                Format: between 3 and 8 (uppercase) letters; preferably customer abbreviation,
                optionally followed by a number.
              </Form.Text>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='name'>
              <Form.Label column sm={2}>
                Customer Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Customer Name'
                  name='name'
                  value={values.name}
                  isInvalid={!!errors.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                Format: Customer name only (no abbreviations, project names, site or location
                names). Only letters (, numbers) and spaces
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='parent_id'>
              <Form.Label column sm={2}>
                Parent
              </Form.Label>
              <Col sm={6}>
                <Form.Select
                  aria-label='Select a parent customer (optional)'
                  name='parent_id'
                  value={values.parent_id}
                  isInvalid={!!errors.parent_id}
                  onChange={handleChange}
                >
                  <option value=''>--Select a parent customer (optional)--</option>

                  {customers.map((customer) => (
                    <option key={customer.customer_id} value={customer.customer_id}>
                      {customer.prefix} {customer.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.parent_id}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Button variant='success' type='submit'>
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

CreateCustomer.propTypes = {
  customers: PropTypes.array,
  changeCustomers: PropTypes.func,
  setCustomerId: PropTypes.func,
};
