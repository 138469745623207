// External Imports
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import Datetime from 'react-datetime';

//Components
import SubscriptionForm from './SubscriptionForm';

import GiveDeleteReason from '../alerts/GiveDeleteReason';

import ListAttachedQosPolicies from '../qos/ListAttachedQosPolicies';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

export default function EditSubscriptions({ site, allHosts, setHostsChanged, hostsReloading }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [customers, setCustomers] = useState([]);
  const [customerWithParents, setCustomerWithParents] = useState([]);

  const [bandwidthPools, setBandwidthPools] = useState([]);
  const [ratePlans, setRatePlans] = useState([]);

  const [update, setUpdate] = useState({});
  const [deleteOpen, openDelete] = useState(false);
  const [actionOpen, openAction] = useState('');
  const [demoVisible, setDemoVisible] = useState('');
  const [qosVisible, setQosVisible] = useState('');

  const [error, setError] = useState({ message: '', severity: 'primary' });

  const { Formik } = formik;
  const now = new Date();
  const day1 = new Date();
  day1.setDate(now.getDate() + 1);
  const day7 = new Date();
  day7.setDate(now.getDate() + 4);

  const schema = yup.object().shape({
    demo_id: yup.string().required(),
    end_time: yup
      .date()
      .min(now.toDateString(), 'End time must be in the future')
      .max(day7.toDateString(), 'Demo can only be extended by max. 3 days')
      .required(),
  });

  // Calculated Data
  const add_customer = (local_customers, filtered, id) => {
    filtered.push(id);
    for (const i in local_customers) {
      if (local_customers[i].customer_id === id) {
        if (local_customers[i].parent_id !== null) {
          filtered = add_customer(local_customers, filtered, local_customers[i].parent_id);
        }
      }
    }
    return filtered;
  };

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setCustomers(Object.values(data));
        setError({ message: '', severity: 'success' });
        return Object.values(data);
      })
      .then((local_customers) => {
        let filtered = [];
        filtered = add_customer(local_customers, filtered, site.customer_id);
        setCustomerWithParents(filtered);
        return filtered;
      })
      .then((local_customerWithParents) => {
        getBandwidthPools(local_customerWithParents);
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customers!', severity: 'danger' });
      });
  };

  const getBandwidthPools = async (local_customerWithParents) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }
    if (local_customerWithParents.length == 0) {
      return;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        return Object.values(data).filter((pool) => {
          if (pool.customer_id == null) {
            const root_children = Object.values(pool.children).filter((child_customer_id) => {
              return child_customer_id == null;
            });
            return root_children.length == 0;
          } else {
            if (Object.keys(pool.children).length > 0) return false;
            return local_customerWithParents.includes(pool.customer_id);
          }
        });
      })
      .then((data) => {
        setBandwidthPools(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve bandwidth pools!', severity: 'danger' });
      });
  };

  const getRatePlans = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/rate_plans', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setRatePlans(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve rate plans!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getRatePlans();
    getCustomers();
  }, []);

  // Event Handlers
  const handleUpdate = (event) => {
    const filtered = allHosts.filter((host) => {
      return host.subnet == event.target.value;
    });
    setUpdate(filtered[0]);
    setDemoVisible('');
    setQosVisible('');
    openDelete(false);
  };

  const handleCancel = (event) => {
    setUpdate({});
  };

  const showDemo = (event) => {
    setUpdate('');
    setQosVisible('');
    setDemoVisible(event.target.value);
  };

  const showQos = (event) => {
    setUpdate('');
    setDemoVisible('');
    setQosVisible(event.target.value);
  };

  const validatePooledBandwidth = async (subnet, newPool, newSubscription, newStatus) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const ip = subnet.split('/')[0];

    const filtered = ratePlans.filter((plan) => {
      if (plan['plan_name'] == newSubscription) {
        return plan;
      }
    });
    const plan = filtered[0];

    const validationData = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pool_validation/' + newPool,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          setError({ message: '', severity: 'success' });
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        const subscriptions = json['subscriptions'].filter((subscription) => {
          return subscription['subnet'] != ip;
        });

        subscriptions.push({
          subnet: ip,
          pool_name: newPool,
          plan_name: newSubscription,
          status: newStatus,
          downlink_cir: plan['downlink_cir'],
          downlink_mir: plan['downlink_mir'],
          uplink_cir: plan['uplink_cir'],
          uplink_mir: plan['uplink_mir'],
        });

        const result = {
          ...json,
          subscriptions: subscriptions,
        };
        return result;
      })
      .catch((error) => {
        setError({
          message: 'Could not fetch bandwidth pool validation data!',
          severity: 'danger',
        });
        return false;
      });
    if (validationData === false) return false;
    if (validationData['customer_id'] === null) return true;

    const validation = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pool_validation/' + newPool,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(validationData),
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        if (!json['result']) {
          setError({ message: json['reason'], severity: 'warning' });
        }
        return json['result'];
      })
      .catch((error) => {
        setError({ message: 'Could not validate bandwidth pool!', severity: 'danger' });
        return false;
      });

    return validation;
  };

  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const validated = await validatePooledBandwidth(
      update['subnet'],
      values['pool_name'],
      values['plan_name'],
      values['status'],
    );
    if (!validated) {
      return;
    }

    const ip = update['subnet'].split('/')[0];
    const subscription = {
      ...values,
      subnet: update['subnet'],
    };
    const hosts = allHosts.filter((host) => {
      return host['subnet'] == update['subnet'];
    });
    const method = hosts[0]['subscription']['status'] == 'DEFAULT' ? 'POST' : 'PUT';
    const url =
      hosts[0]['subscription']['status'] == 'DEFAULT'
        ? '/v1/subscriptions'
        : '/v1/subscriptions/' + ip;
    const action = hosts[0]['subscription']['status'] == 'DEFAULT' ? 'create' : 'update';

    await fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(subscription),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setUpdate({});
          setError({ message: 'Host ' + action + ' has been requested.', severity: 'success' });
          resetForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({
          message: 'Could not ' + action + ' subscription! ' + error,
          severity: 'danger',
        });
      });
  };

  const createDemo = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const validated = await validatePooledBandwidth(
      demoVisible,
      values['pool_name'],
      values['plan_name'],
      'ACTIVE',
    );
    if (!validated) {
      return;
    }

    const demoObj = {
      ...values,
      subnet: demoVisible,
    };

    const ip = demoVisible.split('/')[0];

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip + '/demo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(demoObj),
    })
      .then((response) => {
        if (response.ok) {
          setDemoVisible('');
          setHostsChanged(true);
          setError({
            message: 'Subscription demo creation has been requested.',
            severity: 'success',
          });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create subscription demo! ' + error, severity: 'danger' });
      });
  };

  const updateDemo = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const demoObj = {
      ...values,
      subnet: demoVisible,
    };

    const ip = demoVisible.split('/')[0];

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip + '/demo', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(demoObj),
    })
      .then((response) => {
        if (response.ok) {
          setDemoVisible('');
          setHostsChanged(true);
          setError({
            message: 'Subscription demo update has been requested.',
            severity: 'success',
          });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create update demo! ' + error, severity: 'danger' });
      });
  };

  const showDelete = (event) => {
    openDelete(event.target.value);
  };

  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const ip = update['subnet'].split('/')[0];
    const siteObj = {
      id: ip,
      reason: values.reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(siteObj),
    })
      .then((response) => {
        if (response.ok) {
          openDelete(false);
          setUpdate({});
          setHostsChanged(true);
          setError({ message: 'Subscription deletion has been requested.', severity: 'success' });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not terminate subscription! ' + error, severity: 'danger' });
      });
  };

  const showAction = (event) => {
    openAction(event.target.value);
  };

  const updateSubscriptionStatus = async (subscription, newStatus, reason) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const ip = subscription.subnet.split('/')[0];
    const updated = {
      ...subscription,
      status: newStatus,
      reason: reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(updated),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Subscription update has been requested.', severity: 'success' });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update subscription! ' + error, severity: 'danger' });
      });
  };

  const unsuspendAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    allHosts.map((host) => {
      if (host.subscription.status === 'suspended') {
        updateSubscriptionStatus(host.subscription, 'active', values.reason);
      }
    });

    openAction('');
    setHostsChanged(true);
  };

  const suspendAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    allHosts.map((host) => {
      if (host.subscription.status === 'active') {
        updateSubscriptionStatus(host.subscription, 'suspended', values.reason);
      }
    });

    openAction('');
    setHostsChanged(true);
  };

  const terminateAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    allHosts.map((host) => {
      if (host.subscription.status !== 'DEFAULT') {
        const ip = host.subnet.split('/')[0];
        const siteObj = {
          id: ip,
          reason: values.reason,
        };

        fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify(siteObj),
        })
          .then((response) => {
            if (response.ok) {
              setError({
                message: 'Subscription termination has been requested.',
                severity: 'success',
              });
            } else {
              return response.json().then((text) => {
                throw new Error(text['detail']);
              });
            }
          })
          .catch((error) => {
            setError({ message: 'Could not terminate subscription! ' + error, severity: 'danger' });
          });
      }
    });

    openAction('');
    setHostsChanged(true);
  };

  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      {actionOpen === '' && (
        <>
          <Button variant='success' className='me-3' value='unsuspend' onClick={showAction}>
            Unsuspend All
          </Button>
          <Button variant='warning' className='me-3' value='suspend' onClick={showAction}>
            Suspend All
          </Button>
          <Button variant='danger' value='terminate' onClick={showAction}>
            Terminate All
          </Button>
        </>
      )}
      {actionOpen === 'unsuspend' && (
        <GiveDeleteReason
          formHandler={unsuspendAll}
          cancelHandler={showAction}
          variant='success'
          actionName='Unsuspend'
        />
      )}
      {actionOpen === 'suspend' && (
        <GiveDeleteReason
          formHandler={suspendAll}
          cancelHandler={showAction}
          actionName='Suspend'
        />
      )}
      {actionOpen === 'terminate' && (
        <GiveDeleteReason
          formHandler={terminateAll}
          cancelHandler={showAction}
          variant='danger'
          actionName='Terminate'
        />
      )}

      <hr />

      {hostsReloading != '' ? (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Reloading hosts...</span>
        </Alert>
      ) : (
        <>
          {deleteOpen && <GiveDeleteReason formHandler={handleDelete} cancelHandler={showDelete} />}
          <Table striped>
            <thead>
              <tr>
                <th>Subnet</th>
                <th>Bandwidth Pool</th>
                <th>Rate Plan</th>
                <th>Status</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {allHosts.map((host) => (
                <Fragment key={host.subnet}>
                  {update.subnet === host.subnet ? (
                    <>
                      <tr
                        key={host.subnet + '-update'}
                        className={
                          host.subscription.status == 'active'
                            ? 'table-success'
                            : host.subscription.status == 'suspended'
                            ? 'table-warning'
                            : 'table-danger'
                        }
                      >
                        <th>{host.subnet}</th>
                        <td colSpan={3}>
                          <SubscriptionForm
                            subscription={host.subscription}
                            isDemo={false}
                            handleForm={handleForm}
                            bandwidthPools={bandwidthPools}
                            ratePlans={ratePlans}
                          />
                        </td>
                        <td>
                          {deleteOpen ? (
                            <p>Please enter termination reason.</p>
                          ) : (
                            <>
                              {host.subscription.status !== 'DEFAULT' && (
                                <Button
                                  variant='danger'
                                  className='me-2'
                                  value={true}
                                  onClick={showDelete}
                                >
                                  Terminate
                                </Button>
                              )}
                              <Button variant='info' onClick={handleCancel}>
                                Cancel
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr
                        key={host.subnet + '-view'}
                        className={
                          host.subscription.status == 'active'
                            ? 'table-success'
                            : host.subscription.status == 'suspended'
                            ? 'table-warning'
                            : 'table-danger'
                        }
                      >
                        <th>{host.subnet}</th>
                        {host.subscription.status === 'DEFAULT' ? (
                          <>
                            <td colSpan={3}>
                              <Alert variant='warning'>
                                <span>
                                  No subscription has been assigned to this subnet. Traffic will be
                                  blocked!
                                </span>
                              </Alert>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{host.subscription.pool_name}</td>
                            <td>
                              {host.subscription.active_demo != null ? (
                                <p>
                                  <i>[DEMO]</i> {host.subscription.active_demo}
                                  {host.subscription.qos_hash != null && (
                                    <>&nbsp;({host.subscription.qos_hash})</>
                                  )}
                                  <br />
                                  <s>{host.subscription.plan_name}</s>
                                </p>
                              ) : (
                                <p>
                                  {host.subscription.plan_name}
                                  {host.subscription.qos_hash != null && (
                                    <>&nbsp;({host.subscription.qos_hash})</>
                                  )}
                                </p>
                              )}
                            </td>
                            <td>{host.subscription.status.toUpperCase()}</td>
                          </>
                        )}
                        <td>
                          {demoVisible === host.subnet ? (
                            <Button className='me-2' variant='info' onClick={showDemo}>
                              Cancel
                            </Button>
                          ) : (
                            <>
                              {qosVisible === host.subnet ? (
                                <Button className='me-2' variant='info' onClick={showQos}>
                                  Cancel
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    variant='info'
                                    className='me-2'
                                    value={host.subnet}
                                    onClick={handleUpdate}
                                  >
                                    {host.subscription.status === 'DEFAULT' ? 'Create' : 'Update'}
                                  </Button>
                                  {host.subscription.status !== 'DEFAULT' && (
                                    <>
                                      <Button
                                        variant='primary'
                                        className='me-2'
                                        value={host.subnet}
                                        onClick={showDemo}
                                      >
                                        Demo
                                      </Button>
                                      <Button
                                        variant='primary'
                                        className='me-2'
                                        value={host.subnet}
                                        onClick={showQos}
                                      >
                                        QoS Policies
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      {demoVisible === host.subnet && (
                        <>
                          {Object.keys(host.subscription.demos).map((demo_id) => (
                            <Fragment key={demo_id}>
                              {host.subscription.demos[demo_id].status != 'ARCHIVED' && (
                                <tr
                                  className={
                                    host.subscription.demos[demo_id].status == 'RUNNING'
                                      ? 'table-success'
                                      : host.subscription.demos[demo_id].status == 'EXPIRED'
                                      ? 'table-danger'
                                      : 'table-info'
                                  }
                                >
                                  <th></th>
                                  <td>{host.subscription.demos[demo_id].pool_name}</td>
                                  <td>{host.subscription.demos[demo_id].plan_name}</td>
                                  <td>{host.subscription.demos[demo_id].status}</td>
                                  <td>
                                    {host.subscription.demos[demo_id].status == 'EXPIRED' ? (
                                      <>{host.subscription.demos[demo_id].end_time}</>
                                    ) : (
                                      <Formik
                                        validationSchema={schema}
                                        onSubmit={async (values, { resetForm }) => {
                                          await updateDemo(values, { resetForm });
                                        }}
                                        initialValues={{
                                          demo_id: demo_id,
                                          end_time: host.subscription.demos[demo_id].end_time,
                                        }}
                                      >
                                        {({
                                          handleSubmit,
                                          handleChange,
                                          setFieldValue,
                                          values,
                                          touched,
                                          errors,
                                        }) => (
                                          <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group controlId='demo_id'>
                                              <input
                                                type='hidden'
                                                name='demo_id'
                                                value={values.demo_id}
                                              />
                                            </Form.Group>

                                            <Form.Group
                                              as={Row}
                                              className='mb-3'
                                              controlId='end_time'
                                            >
                                              <Col sm={6}>
                                                <Datetime
                                                  value={values.end_time}
                                                  dateFormat='YYYY-MM-DD'
                                                  timeFormat='HH:mm'
                                                  onChange={async (ms) => {
                                                    setFieldValue('end_time', ms); // TODO FORMATTING ?
                                                  }}
                                                />
                                                <Form.Control
                                                  type='hidden'
                                                  name='end_time'
                                                  value={values.end_time}
                                                  isInvalid={!!errors.end_time}
                                                  onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                  {errors.end_time}
                                                </Form.Control.Feedback>
                                              </Col>

                                              <Col>
                                                <Button
                                                  className='mb-2'
                                                  variant='warning'
                                                  type='submit'
                                                >
                                                  Update
                                                </Button>
                                              </Col>
                                            </Form.Group>
                                          </Form>
                                        )}
                                      </Formik>
                                    )}
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          ))}

                          <tr>
                            <td colSpan={5}>
                              <SubscriptionForm
                                subscription={host.subscription}
                                isDemo={true}
                                handleForm={createDemo}
                                bandwidthPools={bandwidthPools}
                                ratePlans={ratePlans}
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {qosVisible === host.subnet && (
                        <tr>
                          <td colSpan={5}>
                            <ListAttachedQosPolicies site={site} subnet={host.subnet} />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

EditSubscriptions.propTypes = {
  site: PropTypes.object,
  allHosts: PropTypes.array,
  setHostsChanged: PropTypes.func,
  hostsReloading: PropTypes.string,
};
