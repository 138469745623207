// External Imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Components
import EventLog from './EventLog';

import ProcessingAlert from './alerts/ProcessingAlert';

import CustomerAccess from './customers/CustomerAccess';
import EditCustomer from './customers/EditCustomer';
import ShowCustomerTree from './customers/ShowCustomerTree';
import ShowSubscriptionSummary from './customers/ShowSubscriptionSummary';

import CreateBandwidthPool from './bandwidth/CreateBandwidthPool';
import ListBandwidthPools from './bandwidth/ListBandwidthPools';

import ListAttachedQosPolicies from './qos/ListAttachedQosPolicies';

//Hooks
import useToken from '../utils/useToken';
import { getCustomerTree, fetchCustomers } from '../utils/customers';

//Main View
export default function Customer() {
  const { token, isValidToken } = useToken();
  const params = useParams();

  // Local Data
  const [customerId, setCustomerId] = useState(params.customerId);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customersChanged, changeCustomers] = useState(false);

  const [bwPools, setBwPools] = useState([]);
  const [upstreamBwPools, setUpstreamBwPools] = useState([]);
  const [bwPoolsChanged, changeBwPools] = useState(false);

  const [customerWithParents, setCustomerWithParents] = useState([]);

  const [eventLogVisible, setEventLog] = useState(false);
  const [eventLogQuery] = useState({
    customer_id: params.customerId,
  });

  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Calculated Data
  const add_customer = (local_customers, filtered, id) => {
    filtered.push(id);
    for (const i in local_customers) {
      if (local_customers[i].customer_id === id) {
        if (local_customers[i].parent_id !== null) {
          filtered = add_customer(local_customers, filtered, local_customers[i].parent_id);
        }
      }
    }
    return filtered;
  };

  const getCustomers = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    fetchCustomers(token)
      .then((data) => {
        const customerTree = getCustomerTree(data);
        setCustomers(customerTree);
        if (customerId in data) {
          setCustomer(data[customerId]);
          setError({ message: '', severity: 'success' });
          return customerTree;
        } else {
          setError({ message: 'Customer does not exist!', severity: 'warning' });
        }
      })
      .then((local_customers) => {
        let filtered = [];
        filtered = add_customer(local_customers, filtered, customerId);
        setCustomerWithParents(filtered);
        return filtered;
      })
      .then((local_customerWithParents) => {
        getBandwidthPools(local_customerWithParents);
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve customer!', severity: 'danger' });
      });
  };

  const getBandwidthPools = async (local_customerWithParents) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        return Object.values(data).filter((pool) => {
          const globalChildren = Object.keys(pool.children).filter((pool2) => {
            return pool.children[pool2] == null;
          });

          if (globalChildren.length > 0) return false;
          return pool.customer_id == null || local_customerWithParents.includes(pool.customer_id);
        });
      })
      .then((data) => {
        setUpstreamBwPools(data);
        return data;
      })
      .then((data) => {
        return Object.values(data).filter((pool) => {
          return local_customerWithParents.includes(pool.customer_id);
        });
      })
      .then((data) => {
        setBwPools(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve bandwidth pools!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getCustomers();
  }, [customerId, customersChanged]);

  useEffect(() => {
    if (!bwPoolsChanged) {
      getBandwidthPools(customerWithParents);
    }
  }, [bwPoolsChanged]);

  // Event Handlers
  const showEventLog = () => setEventLog(true);

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>
            Customer: [{customerId}] {customer.name}
          </h1>
          {customersChanged && <ProcessingAlert changeFunction={changeCustomers} />}
          {bwPoolsChanged && <ProcessingAlert changeFunction={changeBwPools} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      {customer.customer_id != null && (
        <>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col sm={10}>Edit Customer</Col>
                    <Col sm={2}>
                      <Button variant='info' size='sm' onClick={showEventLog}>
                        Event Log
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={6}>
                      <EditCustomer
                        customer={customer}
                        customers={customers}
                        changeCustomers={changeCustomers}
                      />
                    </Col>
                    <Col sm={6}>
                      <ShowCustomerTree customerId={customerId} customers={customers} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Bandwidth Pools</Card.Header>
                <Card.Body>
                  <ListBandwidthPools
                    bwPools={bwPools}
                    upstreamBwPools={upstreamBwPools}
                    changeBwPools={changeBwPools}
                    customerId={customerId}
                  />
                  <hr />
                  <CreateBandwidthPool
                    bwPools={upstreamBwPools}
                    changeBwPools={changeBwPools}
                    customerId={customerId}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Attached QoS Policies</Card.Header>
                <Card.Body>
                  <ListAttachedQosPolicies customer={customer} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Customer Access</Card.Header>
                <Card.Body>
                  <CustomerAccess customer={customer} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Card>
                <Card.Header>Subscription Summary</Card.Header>
                <Card.Body>
                  <ShowSubscriptionSummary
                    customer={customer}
                    customers={customers}
                    bwPools={upstreamBwPools}
                    changeCustomers={changeCustomers}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <EventLog show={eventLogVisible} setEventLog={setEventLog} query={eventLogQuery} />
        </>
      )}
    </>
  );
}
