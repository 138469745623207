// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';
import ProcessingAlert from '../alerts/ProcessingAlert';

//Hooks
import useToken from '../../utils/useToken';

export default function ListAttachedQosPolicies({ customer, site, subnet }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [policies, setPolicies] = useState([]);
  const [attached, setAttachedPolicies] = useState([]);

  const [updated, setUpdated] = useState(false);
  const [unattachFrom, setUnattachFrom] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getQuery = () => {
    if (subnet != null) {
      return {
        subnet: subnet,
        site_id: site.site_id,
        customer_id: site.customer_id,
      };
    } else if (site != null) {
      return {
        site_id: site.site_id,
        customer_id: site.customer_id,
      };
    } else if (customer != null) {
      return {
        customer_id: customer.customer_id,
      };
    } else {
      setError({ message: 'No customer/site/subnet selected!', severity: 'danger' });
      return {};
    }
  };

  const getAttachment = () => {
    if (subnet != null) {
      return {
        subnet: subnet,
      };
    } else if (site != null) {
      return {
        site_id: site.site_id,
      };
    } else if (customer != null) {
      return {
        customer_id: customer.customer_id,
      };
    } else {
      setError({ message: 'No customer/site/subnet selected!', severity: 'danger' });
      return {};
    }
  };

  const getAttachmentDescription = () => {
    if (subnet != null) {
      return 'Subnet: ' + subnet;
    } else if (site != null) {
      return 'Site: ' + site.site_id;
    } else if (customer != null) {
      return 'Customer: ' + customer.customer_id;
    } else {
      setError({ message: 'No customer/site/subnet selected!', severity: 'danger' });
      return '';
    }
  };

  const { Formik } = formik;
  const schema = yup.object().shape({
    policy_name: yup.string().required(),
    reason: yup.string().required(),
  });

  const getOtherPolicies = async (local_attached) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then((text) => {
          throw new Error(text['detail']);
        });
      })
      .then((data) => {
        return data.filter((policy) => {
          if (policy.configuration != 'UNCONFIGURED') {
            return policy;
          }
        });
      })
      .then((data) => {
        const remove = local_attached.map((pol) => {
          return pol.name;
        });

        return data.filter((policy) => {
          if (!remove.includes(policy.name)) {
            return policy;
          }
        });
      })
      .then((data) => {
        setPolicies(data);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve QoS policies! ' + error, severity: 'danger' });
      });
  };

  const getAttachedPolicies = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const query = getQuery();

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies/attachments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(query),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then((text) => {
          throw new Error(text['detail']);
        });
      })
      .then((data) => {
        setAttachedPolicies(Object.values(data));
        setError({ message: '', severity: 'success' });
        return Object.values(data);
      })
      .then((local_attached) => {
        getOtherPolicies(local_attached);
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve QoS policies! ' + error, severity: 'danger' });
      });
  };

  useEffect(() => {
    getAttachedPolicies();
  }, [updated]);

  // Event Handlers
  const showUnattach = (event) => {
    setUnattachFrom(event.target.value);
  };

  const attachPolicy = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const attachment = getAttachment();
    const body = {
      ...attachment,
      policy_name: values.policy_name,
      reason: values.reason,
    };

    await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/policies/' + values.policy_name + '/attachments',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(body),
      },
    )
      .then((response) => {
        if (response.ok) {
          setUpdated(true);
          setError({ message: 'QoS policy attachment has been requested.', severity: 'success' });
          resetForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not attach QoS policy! ' + error, severity: 'danger' });
      });
  };

  const unattachPolicy = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const attachment = getAttachment();
    const body = {
      ...attachment,
      policy_name: unattachFrom,
      reason: values.reason,
    };

    await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/policies/' + unattachFrom + '/attachments',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(body),
      },
    )
      .then((response) => {
        if (response.ok) {
          setUpdated(true);
          setError({ message: 'QoS policy unattachment has been requested.', severity: 'success' });
          resetForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not un-attach QoS policy! ' + error, severity: 'danger' });
      });
  };

  // TABLE: Create - Review - Delete
  return (
    <>
      {updated && <ProcessingAlert changeFunction={setUpdated} />}
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      <Table striped>
        <thead>
          <tr>
            <th>Policy</th>
            <th>Description</th>
            <th>Details</th>
            <th>Attached To</th>
          </tr>
        </thead>
        <tbody>
          {attached.map((attachment) => (
            <tr key={attachment.name}>
              <td>{attachment.name}</td>
              <td>{attachment.description}</td>
              <td>
                <Table striped='columns'>
                  <tbody>
                    <tr>
                      <td>Applications</td>
                      <td>{attachment.application_group}</td>
                    </tr>
                    <tr>
                      <td>Time Scheme</td>
                      <td>{attachment.time_scheme_config['description']}</td>
                    </tr>
                    <tr>
                      <td>Priority</td>
                      <td>{attachment.priority}</td>
                    </tr>
                    <tr>
                      <td>Configuration</td>
                      <td>
                        {Object.keys(attachment.configuration).map((period_name) => (
                          <li key={period_name}>
                            <b>
                              {attachment.time_scheme_config['periods'][period_name]['description']}{' '}
                            </b>
                            {attachment.type === 'drop' && (
                              <>
                                {attachment.configuration[period_name] === true ? (
                                  <i>DROP</i>
                                ) : (
                                  <i>ALLOW</i>
                                )}
                              </>
                            )}
                            {attachment.type === 'mir' && (
                              <>MIR restricted to {attachment.configuration[period_name]}%</>
                            )}
                            {attachment.type === 'cir' && (
                              <>CIR guaranteed to {attachment.configuration[period_name]}%</>
                            )}
                          </li>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>{attachment.attachment}</td>
              <td>
                {attachment.attachment == getAttachmentDescription() && (
                  <>
                    {unattachFrom === attachment.name ? (
                      <GiveDeleteReason formHandler={unattachPolicy} cancelHandler={showUnattach} />
                    ) : (
                      <Button variant='warning' value={attachment.name} onClick={showUnattach}>
                        Delete
                      </Button>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <hr />
      <Formik
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          await attachPolicy(values, { resetForm });
        }}
        initialValues={{
          policy_name: '',
          reason: '',
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className='mb-1' controlId='policy_name' as={Row}>
              <Form.Label column sm={2}>
                QoS Policy
              </Form.Label>

              <Col sm={6}>
                <Typeahead
                  id='policy_name'
                  name='policy_name'
                  placeholder='Select QoS Policy'
                  options={policies} //FILTER ATTACHED !!!
                  labelKey={(pol) => `[${pol.name}] ${pol.description}`}
                  isInvalid={!!errors.policy_name}
                  onChange={async (suggestion) => {
                    if (suggestion.length === 0) return;
                    const item = suggestion[0];
                    setFieldValue('policy_name', item.name);
                  }}
                />
                <Form.Control
                  type='hidden'
                  name='policy_name'
                  value={values.policy_name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.policy_name}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group className='mb-1' controlId='reason' as={Row}>
              <Form.Label column sm={2}>
                Reason
              </Form.Label>

              <Col sm={6}>
                <Form.Control
                  as='textarea'
                  rows={2}
                  required
                  aria-label='Give a reason'
                  placeholder='Give a reason for this action'
                  name='reason'
                  value={values.reason}
                  onChange={handleChange}
                  isInvalid={!!errors.reason}
                />
                <Form.Control.Feedback type='invalid'>
                  You must enter the reason for this deletion!
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Button variant='success' type='submit'>
              Attach
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

ListAttachedQosPolicies.propTypes = {
  customer: PropTypes.object,
  site: PropTypes.object,
  subnet: PropTypes.string,
};
