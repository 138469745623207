// External Imports
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import * as formik from 'formik';
import * as yup from 'yup';

//Components
import ShowConfiguration from './ShowConfiguration';
import PolicyForm from './PolicyForm';

//import ReloadAlert from '../alerts/ReloadAlert';
import GiveDeleteReason from '../alerts/GiveDeleteReason';

// Hooks
import useToken from '../../utils/useToken';

// Create View
export default function ListPolicies({ policiesChanged, changePolicies }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [policies, setPolicies] = useState([]);
  const [action, setAction] = useState('');
  const [selectedPolicy, selectPolicy] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getPolicies = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setPolicies(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve QoS policies!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getPolicies();
  }, [policiesChanged]);

  // Event Handlers
  const showPolicy = (event) => {
    setAction('view');
    selectPolicy(event.target.value);
  };
  const editPolicy = (event) => {
    setAction('edit');
    selectPolicy(event.target.value);
  };
  const configurePolicy = (event) => {
    setAction('configure');
    selectPolicy(event.target.value);
  };

  // View
  return (
    <Card>
      <Card.Header>QoS Policies</Card.Header>
      <Card.Body>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}

        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Applications</th>
              {/*<th>Sequence</th><th>Priority</th>*/}
              {/*<th>Type</th><th>Time Scheme</th>*/}
              <th>Configuration</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {policies.map((policy) => (
              <Fragment key={policy.name}>
                <tr
                  key={policy.name}
                  className={
                    policy.configuration == 'UNCONFIGURED'
                      ? 'table-danger'
                      : policy.attachments.length === 0
                      ? 'table-warning'
                      : 'table-success'
                  }
                >
                  <th>{policy.name}</th>
                  <td>{policy.description}</td>
                  <td>{policy.application_group}</td>
                  {/*
                <td>{policy.sequence}</td>
                <td>{policy.priority}</td>
                <td>{policy.type}</td>
                <td>{policy.time_scheme}</td>
                */}
                  <td>
                    <ShowConfiguration type={policy.type} configuration={policy.configuration} />
                  </td>
                  <td>
                    {selectedPolicy === policy.name ? (
                      <Button variant='info' onClick={showPolicy}>
                        Cancel
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant='primary'
                          className='me-1'
                          value={policy.name}
                          onClick={editPolicy}
                        >
                          Edit
                        </Button>
                        <Button
                          variant='primary'
                          className='me-1'
                          value={policy.name}
                          onClick={configurePolicy}
                        >
                          Configure
                        </Button>
                        {policy.attachments.length > 0 && (
                          <Button
                            variant='secondary'
                            className='me-1'
                            value={policy.name}
                            onClick={showPolicy}
                          >
                            Attached ({policy.attachments.length})
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                </tr>

                {selectedPolicy === policy.name && (
                  <EditPolicy
                    policy={policy}
                    selectPolicy={selectPolicy}
                    changePolicies={changePolicies}
                    setError={setError}
                    action={action}
                    setAction={setAction}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

function EditPolicy({ policy, selectPolicy, changePolicies, action, setAction }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Event Handlers
  const changeAction = (event) => {
    setAction(event.target.value);
  };

  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const policyObj = {
      name: selectedPolicy,
      reason: values.reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies/' + selectedPolicy, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(policyObj),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'QoS policy deletion has been requested.', severity: 'success' });
          selectPolicy('');
          changePolicies(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not delete QoS policy! ' + error, severity: 'danger' });
      });
  };

  const updatePolicy = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/policies/' + values.name, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'QoS policy update has been requested.', severity: 'success' });
          resetForm();
          selectPolicy('');
          changePolicies(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update QoS policy! ' + error, severity: 'danger' });
      });
  };

  // Edit
  return (
    <tr>
      <td colSpan={5}>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}
        {(action === 'edit' || action === '') && ( // EMPTY = Cancel Delete
          <>
            <PolicyForm handleForm={updatePolicy} setError={setError} policy={policy} />
            {policy.attachments.length === 0 && (
              <>
                <hr />
                <Button variant='warning' value='delete' onClick={changeAction}>
                  Delete
                </Button>
              </>
            )}
          </>
        )}
        {action === 'configure' && (
          <EditPolicyConfiguration
            policy={policy}
            selectPolicy={selectPolicy}
            changePolicies={changePolicies}
            setError={setError}
          />
        )}
        {action === 'view' && (
          <Table>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Site</th>
                <th>Subnet</th>
              </tr>
            </thead>
            <tbody>
              {policy.attachments.map((attachment) => (
                <tr
                  key={attachment.customer_id + '_' + attachment.site_id + '_' + attachment.subnet}
                >
                  <td>{attachment.customer_id}</td>
                  <td>{attachment.site_id}</td>
                  <td>{attachment.subnet}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {action === 'delete' && (
          <GiveDeleteReason formHandler={handleDelete} cancelHandler={changeAction} />
        )}
      </td>
    </tr>
  );
}

function EditPolicyConfiguration({ policy, selectPolicy, changePolicies, setError }) {
  const { token, isValidToken } = useToken();

  const createSchema = () => {
    let shape = {};

    if (policy.type === 'drop') {
      Object.keys(policy.default_config).map(
        (period_name) => (shape[period_name] = yup.boolean().required()),
      );
    }
    if (policy.type === 'mir' || policy.type === 'cir') {
      Object.keys(policy.default_config).map(
        (period_name) => (shape[period_name] = yup.number().min(0).max(100).required()),
      );
    }

    return yup.object().shape(shape);
  };

  const getInitialValues = () => {
    let vals = {};
    Object.keys(policy.default_config).map(
      (period_name) =>
        (vals[period_name] =
          policy.configuration[period_name] == null
            ? policy.default_config[period_name]
            : policy.configuration[period_name]),
    );
    return vals;
  };

  const { Formik } = formik;
  const schema = createSchema();

  // Event Handlers
  const handleConfiguration = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/policies/' + policy.name + '/configuration',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(values),
      },
    )
      .then((response) => {
        if (response.ok) {
          setError({
            message: 'QoS policy configuration has been requested.',
            severity: 'success',
          });
          selectPolicy('');
          changePolicies(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not configure QoS policy! ' + error, severity: 'danger' });
      });
  };

  // Edit
  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        await handleConfiguration(values, { resetForm });
      }}
      initialValues={getInitialValues()}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {Object.keys(policy.default_config).map((period_name) => (
            <Fragment key={period_name}>
              {policy.type === 'drop' && (
                <Form.Group as={Row} className='mb-3' controlId={period_name}>
                  <Form.Label column sm={2}>
                    <b>[{period_name}]</b>
                    <br />
                    Drop Traffic
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Check
                      type='switch'
                      name={period_name}
                      placeholder={'Whether to drop traffic for period ' + period_name}
                      defaultChecked={values[period_name] === 'true'}
                      value='true'
                      onChange={handleChange}
                      isInvalid={!!errors[period_name]}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[period_name]}
                    </Form.Control.Feedback>
                  </Col>
                  <Form.Text as={Col} muted>
                    Triggers at {policy.time_scheme_config['periods'][period_name]['time']} on{' '}
                    {policy.time_scheme_config['periods'][period_name]['days']}
                  </Form.Text>
                </Form.Group>
              )}
              {policy.type === 'mir' && (
                <Form.Group as={Row} className='mb-3' controlId={period_name}>
                  <Form.Label column sm={2}>
                    <b>[{period_name}]</b>
                    <br />
                    MIR %
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      type='text'
                      placeholder={'Enter the MIR percentage for period ' + period_name}
                      name={period_name}
                      value={values[period_name]}
                      onChange={handleChange}
                      isInvalid={!!errors[period_name]}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[period_name]}
                    </Form.Control.Feedback>
                  </Col>
                  <Form.Text as={Col} muted>
                    Triggers at {policy.time_scheme_config['periods'][period_name]['time']} on{' '}
                    {policy.time_scheme_config['periods'][period_name]['days']}
                  </Form.Text>
                </Form.Group>
              )}
              {policy.type === 'cir' && (
                <Form.Group as={Row} className='mb-3' controlId={period_name}>
                  <Form.Label column sm={2}>
                    <b>[{period_name}]</b>
                    <br />
                    CIR %
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      type='text'
                      placeholder={'Enter the CIR percentage for period ' + period_name}
                      name={period_name}
                      value={values[period_name]}
                      onChange={handleChange}
                      isInvalid={!!errors[period_name]}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors[period_name]}
                    </Form.Control.Feedback>
                  </Col>
                  <Form.Text as={Col} muted>
                    Triggers at {policy.time_scheme_config['periods'][period_name]['time']} on{' '}
                    {policy.time_scheme_config['periods'][period_name]['days']}
                  </Form.Text>
                </Form.Group>
              )}
            </Fragment>
          ))}

          <Button variant='warning' className='mb-3' type='submit'>
            Update
          </Button>
        </Form>
      )}
    </Formik>
  );
}

ListPolicies.propTypes = {
  policiesChanged: PropTypes.bool,
  changePolicies: PropTypes.func,
};

EditPolicy.propTypes = {
  policy: PropTypes.object,
  selectPolicy: PropTypes.func,
  changePolicies: PropTypes.func,
  action: PropTypes.string,
  setAction: PropTypes.func,
};

EditPolicyConfiguration.propTypes = {
  policy: PropTypes.object,
  selectPolicy: PropTypes.func,
  changePolicies: PropTypes.func,
  setError: PropTypes.func,
};
