// External Imports
import React from 'react';
import PropTypes from 'prop-types';

export default function ShowConfiguration({ type, configuration }) {
  return (
    <>
      {configuration === 'UNCONFIGURED' ? (
        <p>Not configured!</p>
      ) : (
        <ul>
          {Object.keys(configuration).map((period_name) => (
            <li key={period_name}>
              <b>{period_name}: </b>
              {type === 'drop' && (
                <>{configuration[period_name] === 'true' ? <i>DROP</i> : <i>ALLOW</i>}</>
              )}
              {type === 'mir' && <>MIR restricted to {configuration[period_name]}%</>}
              {type === 'cir' && <>CIR guaranteed to {configuration[period_name]}%</>}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

ShowConfiguration.propTypes = {
  type: PropTypes.string,
  configuration: PropTypes.object,
};
