// External Imports
import React from 'react';
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

//Show Summary Table
export default function ShowSiteSummary({ site }) {
  return (
    <Table striped>
      <tbody>
        <tr>
          <th>Site ID</th>
          <td>{site.site_id}</td>
        </tr>
        <tr>
          <th>Site Name</th>
          <td>{site.name}</td>
        </tr>
        <tr>
          <th>Customer</th>
          <td>
            <a className='btn btn-secondary' href={'/customers/' + site.customer_id}>
              {site.customer_id}
            </a>
            {site.customer_name}
          </td>
        </tr>
        {site.hosts != null && (
          <tr>
            <th>Hosts</th>
            <td>
              <ul>
                {site.hosts.map((host) => (
                  <li key={host}>{host}</li>
                ))}
              </ul>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

ShowSiteSummary.propTypes = {
  site: PropTypes.object,
};
