// External Imports
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import * as formik from 'formik';
import * as yup from 'yup';

// Alert View
export default function GiveDeleteReason({
  formHandler,
  cancelHandler,
  extraValues = {},
  variant = 'warning',
  actionName = 'Delete',
}) {
  const { Formik } = formik;
  const schema = yup.object().shape({
    reason: yup.string().required(),
  });

  return (
    <Alert variant={variant}>
      <Formik
        validationSchema={schema}
        onSubmit={formHandler}
        initialValues={{
          ...extraValues,
          reason: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className='mb-2' controlId='reason'>
              <Form.Control
                as='textarea'
                rows={2}
                required
                aria-label='Give a reason'
                placeholder='Give a reason for this action'
                name='reason'
                value={values.reason}
                onChange={handleChange}
                isInvalid={!!errors.reason}
              />
              <Form.Control.Feedback type='invalid'>
                You must enter the reason for this deletion!
              </Form.Control.Feedback>
            </Form.Group>

            <Button className='me-2' variant='info' onClick={cancelHandler}>
              Cancel
            </Button>
            <Button variant='danger' type='submit'>
              {actionName}
            </Button>
          </Form>
        )}
      </Formik>
    </Alert>
  );
}

GiveDeleteReason.propTypes = {
  formHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  extraValues: PropTypes.object,
  variant: PropTypes.string,
  actionName: PropTypes.string,
};
