// External Imports
import React, { Fragment, useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

// Main View
export default function BandwidthReport() {
  const { token, isValidToken } = useToken();

  // Local Data
  const [report, setReport] = useState([]);
  const [totals, setTotals] = useState({});
  const [utilisation, setUtilisation] = useState({});

  const [accordion, setAccordion] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const [quantileOptions] = useState([0, 1, 5, 10, 25, 50, 75, 90, 95, 99, 100]);
  const [quantilesVisible, setQuantilesVisible] = useState({ 99: '99-th % All Time' });

  const { Formik } = formik;

  const now = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(now.getDate() - 7);

  const schema = yup.object().shape({
    from_time: yup.date().required(), // TODO?
    to_time: yup.date().required(), // TODO?
  });

  const getReport = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/reports/bandwidth', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        const report = Object.values(data);
        report.sort((a, b) => (a['customer_id'] > b['customer_id'] ? 1 : -1));
        setReport(report);

        const totals = {};
        report.map((customer) => {
          Object.keys(customer['networks']).map((network) => {
            const bw = customer['networks'][network];
            if (bw > 0) {
              if (!(network in totals)) {
                totals[network] = 0;
              }
              totals[network] += bw;
            }
          });
        });
        setTotals(totals); //TODO: CONTENTION RATES ??

        setLoading(false);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve report!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getReport();
  }, []);

  // Event Handlers
  const getUtilisation = async (data) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    setLoading(true);

    const result = await Promise.all(
      Object.keys(totals).map((ap_name) => {
        const postData = {
          bandwidth_pool: ap_name,
          from_time: Math.round(data.from_time / 1000),
          to_time: Math.round(data.to_time / 1000),
        };

        return fetch(process.env.REACT_APP_CUSTOMER_URL + '/v1/ap_utilisation_stats', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify(postData),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data) => {
            if (data != false) {
              utilisation[ap_name] = data;
              setUtilisation(utilisation);
              return true;
            } else {
              return 'No data available for ' + ap_name;
            }
          })
          .catch((error) => {
            console.log(error);
            return error.message;
          });
      }),
    );

    const errors = result.filter((a) => {
      return a != true;
    });
    if (errors.length > 0) {
      setAccordion('filter');
      setError({
        message: 'Error while retrieving utilisation: ' + errors, // TODO: <ul>
        severity: 'danger', //TODO: NO DATA = warning...
      });
    } else {
      setAccordion('');
      setError({ message: '', severity: 'success' });
    }
    setLoading(false);

    console.log(utilisation);
  };

  const selectAccordion = (e) => {
    setAccordion(e);
  };

  const isSelected = (value) => {
    if (Object.keys(quantilesVisible).includes(value.toString())) {
      return quantilesVisible[value] != false;
    }
    return false;
  };

  const selectQuantile = (e) => {
    const target = e.currentTarget;
    let visible = { ...quantilesVisible } || {};
    visible[target.id] = target.checked ? target.value : false;
    setQuantilesVisible(visible);
  };

  // Main View
  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Bandwidth Summary</h1>
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>

      <Accordion className='mb-3' onSelect={selectAccordion} activeKey={accordion}>
        <Accordion.Item eventKey='filter'>
          <Accordion.Header>Fetch Utilisation</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col sm={3}>
                <Formik
                  validationSchema={schema}
                  onSubmit={getUtilisation}
                  initialValues={{
                    from_time: lastWeek,
                    to_time: now,
                  }}
                >
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group controlId='from_time' className='mb-3'>
                        <Form.Label>From Time</Form.Label>
                        <Datetime
                          value={values.from_time}
                          dateFormat='YYYY-MM-DD'
                          timeFormat='HH:mm'
                          onChange={async (ms) => {
                            setFieldValue('from_time', ms); // TODO FORMATTING ?
                          }}
                        />
                        <Form.Control
                          type='hidden'
                          name='from_time'
                          value={values.from_time}
                          isInvalid={!!errors.from_time}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.from_time}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId='to_time' className='mb-3'>
                        <Form.Label>To Time</Form.Label>
                        <Datetime
                          value={values.to_time}
                          dateFormat='YYYY-MM-DD'
                          timeFormat='HH:mm'
                          onChange={async (ms) => {
                            setFieldValue('to_time', ms); // TODO FORMATTING ?
                          }}
                        />
                        <Form.Control
                          type='hidden'
                          name='to_time'
                          value={values.to_time}
                          isInvalid={!!errors.to_time}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.to_time}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId='button'>
                        <Form.Label>&nbsp;</Form.Label>
                        <div>
                          <Button variant='success' type='submit'>
                            Fetch
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col sm={2}>
                <h6>All Time</h6>
                {quantileOptions.map((quantile) => (
                  <Form.Check
                    type='checkbox'
                    key={quantile}
                    id={quantile}
                    label={quantile + '-th %'}
                    value={quantile + '-th % All Time'}
                    onChange={selectQuantile}
                    defaultChecked={isSelected(quantile)}
                  />
                ))}
              </Col>
              <Col sm={2}>
                <h6>Peak Time (8:00-16:00)</h6>
                {quantileOptions.map((quantile) => (
                  <Form.Check
                    type='checkbox'
                    key={'peak-' + quantile}
                    id={'peak-' + quantile}
                    label={quantile + '-th %'}
                    value={quantile + '-th % Peak'}
                    onChange={selectQuantile}
                    defaultChecked={isSelected('peak-' + quantile)}
                  />
                ))}
              </Col>
              <Col sm={2}>
                <h6>Off-Peak (16:00-8:00)</h6>
                {quantileOptions.map((quantile) => (
                  <Form.Check
                    type='checkbox'
                    key={'offpeak-' + quantile}
                    id={'offpeak-' + quantile}
                    label={quantile + '-th %'}
                    value={quantile + '-th % Off-Peak'}
                    onChange={selectQuantile}
                    defaultChecked={isSelected('offpeak-' + quantile)}
                  />
                ))}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {loading && (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Data will be loaded.</span>
        </Alert>
      )}

      {Object.keys(totals).length > 0 && (
        <Card className='mb-3'>
          <Card.Header>Bandwidth Network Summary</Card.Header>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>Network</th>
                  <th>Bandwidth</th>
                  {/* TODO: Replace BW with MIR on Fibre Access ? */}
                  {Object.keys(utilisation).length > 0 && (
                    <>
                      {Object.keys(quantilesVisible).map((quantile) => (
                        <Fragment key={quantile}>
                          {quantilesVisible[quantile] != false && (
                            <th>{quantilesVisible[quantile]}</th>
                          )}
                        </Fragment>
                      ))}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.keys(totals).map((network) => (
                  <tr key={network}>
                    <th>{network}</th>
                    <td>{totals[network] / 1024} Mbps</td>
                    {network in utilisation && (
                      <>
                        {Object.keys(quantilesVisible).map((quantile) => (
                          <Fragment key={quantile}>
                            {quantilesVisible[quantile] != false && (
                              <td>
                                {Math.round(utilisation[network][quantile] / 1024)} Mbps
                                <br />
                                {Math.round(
                                  (utilisation[network][quantile] / totals[network]) * 100,
                                )}
                                %
                              </td>
                            )}
                          </Fragment>
                        ))}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          {/* TODO: EXCEL DOWNLOAD IN FOOTER */}
        </Card>
      )}

      {report.length > 0 && (
        <Card className='mb-'>
          <Card.Header>Customer Bandwidth Summary</Card.Header>
          <Card.Body>
            <Table striped>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Bandwidth Pools</th>
                  <th>Sites</th>
                  <th>Subscriptions</th>
                  <th>Network Bandwidth</th>
                </tr>
              </thead>
              <tbody>
                {report.map((row) => (
                  <tr key={row['customer_id']}>
                    <td>
                      <a className='btn btn-primary mb-2' href={'/customers/' + row['customer_id']}>
                        {row['customer_id']}
                      </a>
                      <br />
                      {row['customer_name']}
                    </td>
                    <td className={row['bw_pools'] == 0 ? '' : 'table-info'}>
                      {row['bw_pools'] > 0 ? <p>{row['bw_pools']}</p> : <p>&nbsp;</p>}
                    </td>
                    <td className={row['sites'] == 0 ? '' : 'table-info'}>
                      {row['sites'] > 0 ? <p>{row['sites']}</p> : <p>&nbsp;</p>}
                    </td>
                    <td
                      className={
                        row['subscriptions']['active'] > 0 && row['subscriptions']['suspended'] > 0
                          ? 'table-danger'
                          : row['subscriptions']['active'] > 0 &&
                            row['subscriptions']['suspended'] == 0
                          ? 'table-success'
                          : row['subscriptions']['active'] == 0 &&
                            row['subscriptions']['suspended'] > 0
                          ? 'table-warning'
                          : ''
                      }
                    >
                      {row['subscriptions']['active'] > 0 && (
                        <p>
                          <b>ACTIVE: </b>
                          {row['subscriptions']['active']}
                        </p>
                      )}
                      {row['subscriptions']['suspended'] > 0 && (
                        <p>
                          <b>SUSPENDED: </b>
                          {row['subscriptions']['suspended']}
                        </p>
                      )}
                    </td>
                    <td>
                      {Object.keys(row['networks']).map((network) => (
                        <Fragment key={row['customer_id'] + '_' + network}>
                          {row['networks'][network] > 0 && (
                            <p>
                              <b>{network}: </b>
                              {row['networks'][network] / 1024} Mbps
                            </p>
                          )}
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
