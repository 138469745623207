// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

// Hooks
import useToken from '../../utils/useToken';

export default function PolicyForm({ handleForm, setError, policy }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [applications, setApplications] = useState([]);
  const [timeSchemes, setTimeSchemes] = useState([]);

  const { Formik } = formik;

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[A-Z0-9_]+$/,
        'Only (uppercase) alphanumeric characters and underscores are allowed.',
      )
      .required(),
    description: yup
      .string()
      .matches(
        /^[a-zA-Z0-9 _:]+$/,
        'Only alphanumeric characters, spaces, colons(:) and underscores are allowed.',
      ),
    application_group: yup.string().required(),
    sequence: yup.number().min(2000).max(9999).required(),
    priority: yup.string().oneOf(['background', 'low', 'normal', 'medium', 'high']).required(),
    type: yup.string().oneOf(['drop', 'cir', 'mir']).required(),
    time_scheme: yup.string().required(),
  });

  const getApplications = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/qos/application_groups', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setApplications(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve application groups!', severity: 'danger' });
      });
  };

  const getTimeSchemes = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/qos/time_schemes', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setTimeSchemes(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve time schemes!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getApplications();
    getTimeSchemes();
  }, []);

  // Form
  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        await handleForm(values, { resetForm });
      }}
      initialValues={{
        name: policy == null ? '' : policy.name,
        description: policy == null ? '' : policy.description,
        application_group: policy == null ? '' : policy.application_group,
        sequence: policy == null ? '3000' : policy.sequence,
        priority: policy == null ? 'normal' : policy.priority,
        type: policy == null ? 'drop' : policy.type,
        time_scheme: policy == null ? 'all_time' : policy.time_scheme,
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {policy == null ? (
            <Form.Group as={Row} className='mb-3' controlId='name'>
              <Form.Label column sm={2}>
                Name
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  type='text'
                  placeholder='Enter a unique name'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>
          ) : (
            <Form.Control
              type='hidden'
              name='name'
              value={values.name}
              isInvalid={!!errors.name}
              onChange={handleChange}
            />
          )}

          <Form.Group as={Row} className='mb-3' controlId='description'>
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                type='text'
                placeholder='Enter a full description'
                name='description'
                value={values.description}
                onChange={handleChange}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted></Form.Text>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='application_group'>
            <Form.Label column sm={2}>
              Application Group
            </Form.Label>
            <Col sm={3}>
              <Form.Select
                aria-label='Select the application'
                name='application_group'
                value={values.application_group}
                isInvalid={!!errors.application_group}
                onChange={handleChange}
              >
                <option value=''>-- Select the application --</option>
                {applications.map((application) => (
                  <option key={application} value={application}>
                    {application}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.application_group}
              </Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted>
              Applications can be added to a custom group using SaiSei.
            </Form.Text>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='sequence'>
            <Form.Label column sm={2}>
              Sequence #
            </Form.Label>
            <Col sm={3}>
              <Form.Control
                type='text'
                placeholder='Sequence number'
                name='sequence'
                value={values.sequence}
                onChange={handleChange}
                isInvalid={!!errors.sequence}
              />
              <Form.Control.Feedback type='invalid'>{errors.sequence}</Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted>
              A lower sequence number will have priority over a higher sequence. A flow will only
              get selected once!
            </Form.Text>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='priority'>
            <Form.Label column sm={2}>
              Priority
            </Form.Label>
            <Col sm={3}>
              <Form.Select
                aria-label='Select the application priority'
                name='priority'
                value={values.priority}
                isInvalid={!!errors.priority}
                onChange={handleChange}
              >
                <option value=''>-- Application priority --</option>
                <option value='background'>Background</option>
                <option value='low'>Low</option>
                <option value='normal'>Normal</option>
                <option value='medium'>Medium</option>
                <option value='high'>High</option>
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.priority}</Form.Control.Feedback>
            </Col>
            <Form.Text as={Col} muted>
              The application priority (if not dropped)
            </Form.Text>
          </Form.Group>

          <>
            <Form.Group as={Row} className='mb-3' controlId='type'>
              <Form.Label column sm={2}>
                Action
              </Form.Label>
              <Col sm={3}>
                <Form.Select
                  aria-label='Select the action type'
                  name='type'
                  value={values.type}
                  isInvalid={!!errors.type}
                  onChange={handleChange}
                  disabled={policy !== undefined}
                >
                  <option value=''>-- Action type --</option>
                  <option value='drop'>Drop</option>
                  {/*<option value='cir'>Enforce CIR</option>*/}
                  <option value='mir'>Enforce MIR</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.type}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                {policy === undefined ? (
                  <>Whether to drop traffic or enforce the bandwidth</>
                ) : (
                  <>The Action can not be updated once configured!</>
                )}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='time_scheme'>
              <Form.Label column sm={2}>
                Time Scheme
              </Form.Label>
              <Col sm={3}>
                <Form.Select
                  aria-label='Select a Time Scheme'
                  name='time_scheme'
                  value={values.time_scheme}
                  isInvalid={!!errors.time_scheme}
                  onChange={handleChange}
                  disabled={policy !== undefined}
                >
                  <option value=''>-- Time scheme --</option>
                  {timeSchemes.map((timeScheme) => (
                    <option key={timeScheme.name} value={timeScheme.name}>
                      {timeScheme.description}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.time_scheme}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted>
                {policy === undefined ? (
                  <>This will define several times at which the action will be modified.</>
                ) : (
                  <>The Time Scheme can not be updated once configured!</>
                )}
              </Form.Text>
            </Form.Group>
          </>

          {policy === undefined ? (
            <Button variant='success' type='submit'>
              Create
            </Button>
          ) : (
            <Button variant='warning' type='submit'>
              Update
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
}

PolicyForm.propTypes = {
  handleForm: PropTypes.func,
  setError: PropTypes.func,
  policy: PropTypes.object,
};
