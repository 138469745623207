// External Imports
import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

//Alert View
export default function ProcessingAlert({ changeFunction }) {
  const [error, setError] = useState({ message: '', severity: 'primary' });

  useWebSocket(process.env.REACT_APP_WEBSOCKET_URL + '/queue_state', {
    onMessage: (msg) => {
      const json_msg = JSON.parse(msg.data);
      const queue_state = json_msg.queue_state;

      if (queue_state === 'PROCESSED') {
        changeFunction(false);
      }
      if (queue_state === 'ERROR') {
        const queue_error = json_msg.queue_error;
        setError({ message: queue_error, severity: 'danger' });
      }
    },
  });

  return (
    <>
      {error.message != '' ? (
        <Modal
          show={true}
          centered
          backdrop='static'
          keyboard={false}
          onHide={() => changeFunction(false)}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Process Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          </Modal.Body>
          <Modal.Footer>Do not retry this action! Please contact Tier3 support first.</Modal.Footer>
        </Modal>
      ) : (
        <Modal show={true} centered backdrop='static' keyboard={false}>
          <Modal.Header>
            <Modal.Title>Processing...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant='warning'>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
              <span className='ms-2'>Processing...</span>
              <p>Data will be reloaded once changes are made.</p>
            </Alert>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

ProcessingAlert.propTypes = {
  changeFunction: PropTypes.func,
};
