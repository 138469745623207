// External Imports
import { React, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

//Components
import GiveDeleteReason from '../alerts/GiveDeleteReason';

//Hooks
import useToken from '../../utils/useToken';

export default function ShowSubscriptionSummary({ customer, customers, bwPools, changeCustomers }) {
  const { token, isValidToken } = useToken();

  const [subscriptions, setSubscriptions] = useState([]);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);

  const [actionOpen, openAction] = useState('');

  const [error, setError] = useState({ message: '', severity: 'primary' });

  const addChildren = (list, customer_id) => {
    for (const i in customers) {
      if (customers[i].parent_id === customer_id) {
        list.push(customers[i]);
        list = addChildren(list, customers[i].customer_id);
      }
    }
    return list;
  };

  const filterCustomers = () => {
    const list = [];
    list.push(customer);
    return addChildren(list, customer.customer_id);
  };

  const getSites = () => {
    const list = filterCustomers();
    let sites = [];
    list.map((c) => {
      sites = sites.concat(c.sites);
    });
    setSites(sites);
    return sites;
  };

  const getSubscriptions = async (local_sites) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }
    if (local_sites.length == 0) {
      setLoading(false);
      return;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/sites/lookup?subs=true', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(local_sites),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((siteList) => {
        return siteList.map((s) => {
          return Object.values(s.subscriptions).map((sub) => {
            return {
              ...sub,
              site_id: s.site_id,
              customer_id: s.customer_id,
            };
          });
        });
      })
      .then((subsList) => {
        return subsList.flat().reduce(function (rv, x) {
          (rv[x['pool_name']] = rv[x['pool_name']] || []).push(x);
          return rv;
        }, {});
      })
      .then((hierarchy) => {
        setSubscriptions(hierarchy);
        setError({ message: '', severity: 'success' });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve site!', severity: 'danger' });
      });
  };

  useEffect(() => {
    const local_sites = getSites();
    getSubscriptions(local_sites);
  }, [customer, customers]);

  // Event Handlers
  const showAction = (event) => {
    openAction(event.target.value);
  };

  const updateSubscriptionStatus = async (subscription, newStatus, reason) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const ip = subscription.subnet.split('/')[0];
    const updated = {
      ...subscription,
      status: newStatus,
      reason: reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(updated),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Subscription update has been requested.', severity: 'success' });
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not update subscription! ' + error, severity: 'danger' });
      });
  };

  const unsuspendAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    Object.keys(subscriptions).map((pool_name) => {
      subscriptions[pool_name].map((subscription) => {
        if (pool_name != 'undefined' && subscription.status === 'suspended') {
          updateSubscriptionStatus(subscription, 'active', values.reason);
        }
      });
    });

    openAction('');
    setLoading(true);
    changeCustomers(true);
  };

  const suspendAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    Object.keys(subscriptions).map((pool_name) => {
      subscriptions[pool_name].map((subscription) => {
        if (pool_name != 'undefined' && subscription.status === 'active') {
          updateSubscriptionStatus(subscription, 'suspended', values.reason);
        }
      });
    });

    openAction('');
    setLoading(true);
    changeCustomers(true);
  };

  const terminateAll = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    Object.keys(subscriptions).map((pool_name) => {
      subscriptions[pool_name].map((subscription) => {
        if (pool_name != 'undefined') {
          const ip = subscription.subnet.split('/')[0];
          const siteObj = {
            id: ip,
            reason: values.reason,
          };

          fetch(process.env.REACT_APP_BACKEND_URL + '/v1/subscriptions/' + ip, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(siteObj),
          })
            .then((response) => {
              if (response.ok) {
                setError({
                  message: 'Subscription termination has been requested.',
                  severity: 'success',
                });
              } else {
                return response.json().then((text) => {
                  throw new Error(text['detail']);
                });
              }
            })
            .catch((error) => {
              setError({
                message: 'Could not terminate subscription! ' + error,
                severity: 'danger',
              });
            });
        }
      });
    });

    openAction('');
    setLoading(true);
    changeCustomers(true);
  };

  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      {actionOpen === '' && (
        <>
          <Button variant='success' className='me-3' value='unsuspend' onClick={showAction}>
            Unsuspend All
          </Button>
          <Button variant='warning' className='me-3' value='suspend' onClick={showAction}>
            Suspend All
          </Button>
          <Button variant='danger' value='terminate' onClick={showAction}>
            Terminate All
          </Button>
        </>
      )}
      {actionOpen === 'unsuspend' && (
        <GiveDeleteReason
          formHandler={unsuspendAll}
          cancelHandler={showAction}
          variant='success'
          actionName='Unsuspend'
        />
      )}
      {actionOpen === 'suspend' && (
        <GiveDeleteReason
          formHandler={suspendAll}
          cancelHandler={showAction}
          actionName='Suspend'
        />
      )}
      {actionOpen === 'terminate' && (
        <GiveDeleteReason
          formHandler={terminateAll}
          cancelHandler={showAction}
          variant='danger'
          actionName='Terminate'
        />
      )}

      <hr />

      {loading ? (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Loading data...</span>
        </Alert>
      ) : (
        <Table striped>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Site</th>
              <th>Subnet</th>
              <th>Rate Plan</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(subscriptions).map((pool_name) => (
              <Fragment key={pool_name}>
                {pool_name === 'undefined' ? (
                  <tr key='undefined-header'>
                    <th colSpan={4} className='text-center'>
                      NO SUBSCRIPTION - TRAFFIC BLOCKED !
                    </th>
                  </tr>
                ) : (
                  <Fragment key={pool_name + '-header'}>
                    {bwPools.map((bwPool) => (
                      <Fragment key={'iterate-' + pool_name + '-' + bwPool.pool_name}>
                        {bwPool.pool_name == pool_name && (
                          <Fragment key={pool_name + '-pool'}>
                            {bwPool.customer_id !== null ? (
                              <tr key={pool_name + '-row'}>
                                <th colSpan={4} className='text-center'>
                                  {pool_name} ({bwPool.uplink_mir / 1024} /{' '}
                                  {bwPool.downlink_mir / 1024} Mbps)
                                </th>
                              </tr>
                            ) : (
                              <tr key={pool_name + '-row'}>
                                <th colSpan={4} className='text-center'>
                                  {pool_name}
                                </th>
                              </tr>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                )}

                {subscriptions[pool_name].map((subscription) => (
                  <tr
                    key={subscription.subnet}
                    className={
                      subscription.status == 'active'
                        ? 'table-success'
                        : subscription.status == 'suspended'
                        ? 'table-warning'
                        : 'table-danger'
                    }
                  >
                    <td>
                      <a
                        className='btn btn-secondary'
                        href={'/customers/' + subscription.customer_id}
                      >
                        {subscription.customer_id}
                      </a>
                    </td>
                    <td>
                      <a className='btn btn-secondary' href={'/sites/' + subscription.site_id}>
                        {subscription.site_id}
                      </a>
                    </td>
                    <td>{subscription.subnet}</td>
                    <td>{subscription.plan_name}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

ShowSubscriptionSummary.propTypes = {
  customer: PropTypes.object,
  customers: PropTypes.array,
  bwPools: PropTypes.array,
  changeCustomers: PropTypes.func,
};
