// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

//Edit Table
export default function CreateTransport({
  site,
  setHostsChanged,
  transportType,
  getAvailableSubnets,
  getAvailableVlans,
  getAvailableInterfaces,
  setTransportType,
  hostsReloading,
  setHostsReloading,
  setErrorUpstream,
}) {
  //Local Data
  const siteId = site.site_id;
  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Event Handlers
  const cancelForm = async () => {
    setTransportType('');
  };

  // View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      {transportType === 'pppoe' && (
        <CreatePPPoETransport
          siteId={siteId}
          setHostsChanged={setHostsChanged}
          setError={setError}
          getAvailableSubnets={getAvailableSubnets}
          cancelForm={cancelForm}
          setHostsReloading={setHostsReloading}
          setErrorUpstream={setErrorUpstream}
        />
      )}
      {transportType === 'bgp' && (
        <CreateBGPTransport
          site={site}
          setHostsChanged={setHostsChanged}
          setError={setError}
          getAvailableSubnets={getAvailableSubnets}
          getAvailableVlans={getAvailableVlans}
          getAvailableInterfaces={getAvailableInterfaces}
          cancelForm={cancelForm}
          setHostsReloading={setHostsReloading}
          setErrorUpstream={setErrorUpstream}
        />
      )}
    </>
  );
}

function CreatePPPoETransport({
  siteId,
  setHostsChanged,
  setError,
  getAvailableSubnets,
  cancelForm,
  setHostsReloading,
  setErrorUpstream,
}) {
  const { token, isValidToken } = useToken();

  // Local Data
  const suggestPassword = () => {
    const chars = '123456789abcdefghijkmnopqrstuvwxyzABDEFGHKLMNPQRST';

    let password = '';
    for (var i = 0; i <= 8; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  };

  const [availableSubnets, setAvailableSubnets] = useState([]);
  const [subnetsLoading, setSubnetsLoading] = useState(true);
  const [suggestedPassword, setPassword] = useState(suggestPassword());

  const schema = yup.object().shape({
    subnet: yup.string().required(),
    alias: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_ \-]+$/,
        'Only alphanumeric characters, spaces, underscores (_) and dashes (-) are allowed.',
      ),
    pppoe_username: yup
      .string()
      .matches(/^[A-Z0-9]+$/, 'Only (uppercase) alphanumeric characters are allowed.')
      .min(5)
      .max(15)
      .required(),
    pppoe_password: yup
      .string()
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed.')
      .min(8)
      .max(12)
      .required(),
  });
  const { Formik } = formik;

  const updateAvailableSubnets = async () => {
    const subnets = await getAvailableSubnets('PPPoE Transport', 32);
    setAvailableSubnets(subnets);
    setSubnetsLoading(false);
  };

  useEffect(() => {
    updateAvailableSubnets();
  }, []);

  // Event Handlers
  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const newTransport = {
      site_id: siteId,
      ...values,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/transport/pppoe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newTransport),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setHostsReloading('transport');
          setError({
            message: '',
            severity: 'success',
          });
          setErrorUpstream({
            message: 'PPPoE Transport creation has been requested.',
            severity: 'success',
          });
          resetForm();
          cancelForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create pppoe transport! ' + error, severity: 'danger' });
      });
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          await handleForm(values, { resetForm });
        }}
        initialValues={{
          subnet: '',
          alias: '',
          pppoe_username: siteId,
          pppoe_password: suggestedPassword,
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='pppoe_username'>
              <Form.Label column sm={2}>
                Username
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Username for PPPoE'
                  name='pppoe_username'
                  value={values.pppoe_username}
                  isInvalid={!!errors.pppoe_username}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.pppoe_username}
                </Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='pppoe_password'>
              <Form.Label column sm={2}>
                Password
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  type='text'
                  placeholder='Enter Password for PPPoE'
                  name='pppoe_password'
                  value={values.pppoe_password}
                  isInvalid={!!errors.pppoe_password}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.pppoe_password}
                </Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='subnet'>
              <Form.Label column sm={2}>
                Subnet
              </Form.Label>
              <Col sm={10}>
                <Typeahead
                  id='subnet'
                  name='subnet'
                  placeholder='Enter Subnet'
                  options={availableSubnets}
                  isLoading={subnetsLoading}
                  labelKey={(option) => `[${option.prefix_name}] ${option.subnet}`}
                  //allowNew -> Can't combine with [prefix name]
                  newSelectionPrefix='Specify the subnet manually: '
                  isInvalid={!!errors.subnet}
                  onChange={async (suggestion) => {
                    if (suggestion.length === 0) return;
                    const item = suggestion[0];
                    setFieldValue('subnet', item.subnet);
                  }}
                />
                <Form.Control
                  type='hidden'
                  name='subnet'
                  value={values.subnet}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.subnet}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='alias'>
              <Form.Label column sm={2}>
                Alias
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type='text'
                  placeholder='Optionally enter alias for this PPPoE session'
                  name='alias'
                  value={values.alias}
                  isInvalid={!!errors.alias}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.alias}</Form.Control.Feedback>
              </Col>
              <Form.Text as={Col} muted></Form.Text>
            </Form.Group>

            <Button variant='success' className='me-2' type='submit'>
              Create
            </Button>
            <Button variant='info' value='' onClick={cancelForm}>
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

function CreateBGPTransport({
  site,
  setHostsChanged,
  setError,
  getAvailableSubnets,
  getAvailableVlans,
  getAvailableInterfaces,
  cancelForm,
  setHostsReloading,
  setErrorUpstream,
}) {
  const { token, isValidToken } = useToken();

  // Local Data
  const siteId = site.site_id;

  const [subnetsLoading, setSubnetsLoading] = useState(true);
  const [availableSubnets, setAvailableSubnets] = useState([]);

  const [vlansLoading, setVlansLoading] = useState(true);
  const [availableVlans, setAvailableVlans] = useState([]);

  const [deviceGroups, setDeviceGroups] = useState([]);
  const [deviceInterfaces, setDeviceInterfaces] = useState([]);

  const bgp_schema = yup.object().shape({
    subnet: yup.string().required(),
    alias: yup.string(),
    hiera_path: yup.string().required(),
    interface_name: yup.string().required(),
    vlan_id: yup.number().min(1).max(4096).required(),
    priority: yup.string().required(),
    redundancy_type: yup.string().required(),
    pop_redundancy: yup.string().required(),
  });
  const { Formik } = formik;

  const updateAvailableSubnets = async () => {
    const subnets = await getAvailableSubnets('BGP Transport', 29);
    setAvailableSubnets(subnets);
    setSubnetsLoading(false);
  };

  const updateAvailableVlans = async () => {
    const vlans = await getAvailableVlans('Customer BGP Transport');
    setAvailableVlans(vlans);
    setVlansLoading(false);
  };

  const updateAvailableInterfaces = async () => {
    const dev_interfaces = await getAvailableInterfaces('bgp');
    setDeviceGroups(dev_interfaces);
  };

  useEffect(() => {
    updateAvailableSubnets();
    updateAvailableVlans();
    updateAvailableInterfaces();
  }, []);

  // Event Handlers
  const updateDeviceInterfaces = async (hiera_path) => {
    deviceGroups.map(function (group) {
      if (group.hiera_path === hiera_path) {
        setDeviceInterfaces(Object.keys(group.interfaces));
      }
    });
  };

  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const newTransport = {
      site_id: siteId,
      ...values,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/transport/bgp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newTransport),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setHostsReloading('transport');
          setError({
            message: '',
            severity: 'success',
          });
          setErrorUpstream({
            message: 'BGP Transport creation has been requested.',
            severity: 'success',
          });
          resetForm();
          cancelForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create BGP transport! ' + error, severity: 'danger' });
      });
  };

  return (
    <>
      {site.asn === null ? (
        <Alert variant='warning'>
          <span>
            Customer/Site does not have a valid AS number configured. This is required for BGP
            transports!
          </span>
        </Alert>
      ) : (
        <>
          <Formik
            validationSchema={bgp_schema}
            onSubmit={async (values, { resetForm }) => {
              await handleForm(values, { resetForm });
            }}
            initialValues={{
              asn: site.asn,
              subnet: '',
              alias: '',
              hiera_path: '',
              interface_name: '',
              vlan_id: '',
              priority: 'primary',
              redundancy_type: 'vrrp',
              pop_redundancy: 'normal',
            }}
          >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Row} className='mb-3' controlId='subnet'>
                  <Form.Label column sm={4}>
                    ASN
                  </Form.Label>

                  <Col sm={4}>
                    <Form.Control
                      type='text'
                      placeholder='Enter AS Number'
                      name='asn'
                      value={site.asn}
                      isInvalid={!!errors.asn}
                      onChange={handleChange}
                      disabled
                    />
                    <Form.Control.Feedback type='invalid'>{errors.asn}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='subnet'>
                  <Form.Label column sm={4}>
                    Subnet
                  </Form.Label>
                  <Col sm={8}>
                    <Typeahead
                      id='subnet'
                      name='subnet'
                      placeholder='Enter Subnet'
                      options={availableSubnets}
                      isLoading={subnetsLoading}
                      labelKey={(option) => `[${option.prefix_name}] ${option.subnet}`}
                      //allowNew -> Can't combine with [prefix name]
                      //newSelectionPrefix='Specify the subnet manually: '
                      isInvalid={!!errors.subnet}
                      onChange={async (suggestion) => {
                        if (suggestion.length === 0) return;
                        const item = suggestion[0];
                        setFieldValue('subnet', item.subnet);
                      }}
                    />
                    <Form.Control
                      type='hidden'
                      name='subnet'
                      value={values.subnet}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.subnet}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='alias'>
                  <Form.Label column sm={4}>
                    Alias
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type='text'
                      placeholder='Optionally enter alias for this BGP session'
                      name='alias'
                      value={values.alias}
                      isInvalid={!!errors.alias}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.alias}</Form.Control.Feedback>
                  </Col>
                  <Form.Text as={Col} muted></Form.Text>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='hiera_path'>
                  <Form.Label column sm={4}>
                    Device Group
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      aria-label='Select the device group'
                      name='hiera_path'
                      value={values.hiera_path}
                      isInvalid={!!errors.hiera_path}
                      onChange={async (e) => {
                        await handleChange(e);
                        updateDeviceInterfaces(e.target.value);
                      }}
                    >
                      <option key='' value=''>
                        -- Select a device group --
                      </option>
                      {deviceGroups.map((intf) => (
                        <option key={intf.device_group} value={intf.hiera_path}>
                          {intf.device_group}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>
                      {errors.hiera_path}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='interface_name'>
                  <Form.Label column sm={4}>
                    Interface
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      aria-label='Select the interface'
                      name='interface_name'
                      value={values.interface_name}
                      isInvalid={!!errors.interface_name}
                      onChange={handleChange}
                    >
                      <option key='' value=''>
                        -- Select an interface --
                      </option>
                      {deviceInterfaces.map((intf) => (
                        <option key={intf} value={intf}>
                          {intf}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>
                      {errors.interface_name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='vlan_id'>
                  <Form.Label column sm={4}>
                    VLAN
                  </Form.Label>
                  <Col sm={8}>
                    <Typeahead
                      id='vlan_suggest'
                      name='vlan_suggest'
                      defaultInputValue={values.vlan_id}
                      placeholder='Enter VLAN ID'
                      options={availableVlans}
                      isLoading={vlansLoading}
                      labelKey={(option) => `[${option.vlan_name}] ${option.vlan_id}`}
                      isInvalid={!!errors.vlan_id}
                      onChange={async (suggestion) => {
                        if (suggestion.length === 0) return;
                        const item = suggestion[0];
                        setFieldValue('vlan_id', item.vlan_id);
                      }}
                    />
                    <Form.Control
                      type='hidden'
                      name='vlan_id'
                      value={values.vlan_id}
                      onChange={handleChange}
                    />

                    <Form.Control.Feedback type='invalid'>{errors.vlan_id}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='priority'>
                  <Form.Label column sm={4}>
                    Session Priority
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      aria-label='Select the routing priority'
                      name='priority'
                      value={values.priority}
                      isInvalid={!!errors.priority}
                      onChange={handleChange}
                    >
                      <option key='primary' value='primary'>
                        Primary session
                      </option>
                      <option key='redundant' value='redundant'>
                        First redundant session
                      </option>
                      <option key='other' value='other'>
                        Extra redundancy (last resort)
                      </option>
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>{errors.priority}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='redundancy_type'>
                  <Form.Label column sm={4}>
                    Device Redundancy
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      aria-label='Select the device redundancy type'
                      name='redundancy_type'
                      value={values.redundancy_type}
                      isInvalid={!!errors.redundancy_type}
                      onChange={handleChange}
                    >
                      <option key='none' value='none'>
                        Single Device (No redundancy)
                      </option>
                      <option key='vrrp' value='vrrp'>
                        Virtual IP (VRRP)
                      </option>
                      <option key='dual_session' value='dual_session'>
                        Multiple BGP Sessions
                      </option>
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>
                      {errors.redundancy_type}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId='pop_redundancy'>
                  <Form.Label column sm={4}>
                    POP Redundancy
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      aria-label='Select the POP redundancy type'
                      name='pop_redundancy'
                      value={values.pop_redundancy}
                      isInvalid={!!errors.pop_redundancy}
                      onChange={handleChange}
                    >
                      <option key='normal' value='normal'>
                        Normal Network Priority
                      </option>
                      <option key='swapped' value='swapped'>
                        Swap Network Priority
                      </option>
                      <option key='twr1' value='twr1'>
                        Always Prefer TWR1
                      </option>
                      <option key='twrb' value='twrb'>
                        Always Prefer TWR B
                      </option>
                    </Form.Select>

                    <Form.Control.Feedback type='invalid'>
                      {errors.pop_redundancy}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Button variant='success' className='me-2' type='submit'>
                  Create
                </Button>
                <Button variant='info' value='' onClick={cancelForm}>
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

CreateTransport.propTypes = {
  site: PropTypes.object,
  setHostsChanged: PropTypes.func,
  transportType: PropTypes.string,
  getAvailableSubnets: PropTypes.func,
  getAvailableVlans: PropTypes.func,
  getAvailableInterfaces: PropTypes.func,
  setTransportType: PropTypes.func,
  hostsReloading: PropTypes.string,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};

CreatePPPoETransport.propTypes = {
  siteId: PropTypes.string,
  setHostsChanged: PropTypes.func,
  setError: PropTypes.func,
  getAvailableSubnets: PropTypes.func,
  cancelForm: PropTypes.func,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};

CreateBGPTransport.propTypes = {
  site: PropTypes.object,
  setHostsChanged: PropTypes.func,
  setError: PropTypes.func,
  getAvailableSubnets: PropTypes.func,
  getAvailableVlans: PropTypes.func,
  getAvailableInterfaces: PropTypes.func,
  cancelForm: PropTypes.func,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};
