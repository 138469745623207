// External Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

//Hooks
import useToken from '../../utils/useToken';

//Edit Table
export default function CreateRoute({
  siteId,
  setHostsChanged,
  routeSize,
  getAvailableSubnets,
  setRouteSize,
  hostsReloading,
  setHostsReloading,
  setErrorUpstream,
}) {
  const { token, isValidToken } = useToken();

  //Local Data
  const [availableSubnets, setAvailableSubnets] = useState([]);
  const [subnetsLoading, setSubnetsLoading] = useState(true);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const schema = yup.object().shape({
    subnet: yup.string().required(),
    alias: yup.string(),
  });
  const { Formik } = formik;

  const updateAvailableSubnets = async () => {
    const subnets = await getAvailableSubnets('Customer Route', routeSize);
    setAvailableSubnets(subnets);
    setSubnetsLoading(false);
  };

  useEffect(() => {
    updateAvailableSubnets();
  }, [routeSize]);

  // Event Handlers
  const cancelForm = async () => {
    setRouteSize('');
  };

  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const newRoute = {
      site_id: siteId,
      ...values,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/route', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newRoute),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setHostsReloading('route');
          setError({
            message: '',
            severity: 'success',
          });
          setErrorUpstream({ message: 'Route creation has been requested.', severity: 'success' });
          resetForm();
          setRouteSize('');
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create route! ' + error, severity: 'danger' });
      });
  };

  // View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}
      {routeSize != '' && (
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await handleForm(values, { resetForm });
          }}
          initialValues={{
            subnet: '',
            alias: '',
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} className='mb-3' controlId='subnet'>
                <Form.Label column sm={2}>
                  Subnet
                </Form.Label>
                <Col sm={10}>
                  <Typeahead
                    id='subnet'
                    name='subnet'
                    placeholder='Enter Subnet'
                    options={availableSubnets}
                    isLoading={subnetsLoading}
                    labelKey={(option) => `[${option.prefix_name}] ${option.subnet}`}
                    //allowNew -> Can't combine with [prefix name]
                    newSelectionPrefix='Specify the subnet manually: '
                    isInvalid={!!errors.subnet}
                    onChange={async (suggestion) => {
                      if (suggestion.length === 0) return;
                      const item = suggestion[0];
                      setFieldValue('subnet', item.subnet);
                    }}
                  />
                  <Form.Control
                    type='hidden'
                    name='subnet'
                    value={values.subnet}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.subnet}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className='mb-3' controlId='alias'>
                <Form.Label column sm={2}>
                  Alias
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type='text'
                    placeholder='Optionally enter alias for this route'
                    name='alias'
                    value={values.alias}
                    isInvalid={!!errors.alias}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.alias}</Form.Control.Feedback>
                </Col>
                <Form.Text as={Col} muted></Form.Text>
              </Form.Group>

              <Button variant='success' className='me-2' type='submit'>
                Create
              </Button>
              <Button variant='info' value='' onClick={cancelForm}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

CreateRoute.propTypes = {
  siteId: PropTypes.string,
  setHostsChanged: PropTypes.func,
  routeSize: PropTypes.number,
  getAvailableSubnets: PropTypes.func,
  setRouteSize: PropTypes.func,
  hostsReloading: PropTypes.string,
  setHostsReloading: PropTypes.func,
  setErrorUpstream: PropTypes.func,
};
