// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

//Components
import BandwidthPoolForm from './BandwidthPoolForm';

// Hooks
import useToken from '../../utils/useToken';

//Create View
export default function CreateBandwidthPool({ bwPools, changeBwPools, customerId }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const filteredBwPools = bwPools.filter((pool) => {
    // bwPools is already filtered for customer ID.
    if (customerId !== '') {
      return true;
    } else {
      // The pool should not contain subscriptions!
      if (pool.subscriptions.length === 0) {
        const globalChildren = Object.keys(pool.children).filter((pool2) => {
          return pool.children[pool2] !== null;
        });
        // The pool should not contain bandwidth pools belonging to customers!
        return globalChildren.length === 0;
      }
      return false;
    }
  });

  // Event Handlers
  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    let newPool = {};
    for (const [k, v] of Object.entries(values)) {
      if (v != '') {
        if (['uplink_mir', 'downlink_mir', 'uplink_cir', 'downlink_cir'].includes(k)) {
          newPool[k] = v * 1024;
        } else {
          newPool[k] = v;
        }
      }
    }

    if (customerId != null && customerId != '') {
      newPool['customer_id'] = customerId;
    }

    if (values.dynamic === true) {
      newPool['uplink_mir'] = 'dynamic';
      newPool['downlink_mir'] = 'dynamic';
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/bandwidth_pools', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(newPool),
    })
      .then((response) => {
        if (response.ok) {
          changeBwPools(true);
          setError({ message: 'Bandwidth Pool creation has been requested.', severity: 'success' });
          resetForm();
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create bandwidth pool! ' + error, severity: 'danger' });
      });
  };

  // Create View
  return (
    <>
      {error.message != '' && (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      )}

      <BandwidthPoolForm
        isCustomerPool={customerId !== ''}
        pool={null}
        bwPools={filteredBwPools}
        handleForm={handleForm}
      />
    </>
  );
}

CreateBandwidthPool.propTypes = {
  bwPools: PropTypes.array,
  changeBwPools: PropTypes.func,
  customerId: PropTypes.string,
};
