// Imports
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'react-datetime/css/react-datetime.css';

import './style/App.css';

import App from './App';
import reportWebVitals from './utils/reportWebVitals';

// Insert application into div element with id : "root"
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// Extra functionality: Performance logging
reportWebVitals();
