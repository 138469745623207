// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//Components
import EditRoutes from '../hosts/EditRoutes';
import EditTransports from '../hosts/EditTransports';
import CreateRoute from '../hosts/CreateRoute';
import CreateTransport from '../hosts/CreateTransport';

//Hooks
import useToken from '../../utils/useToken';

//Generic View
export default function EditHosts({
  type,
  site,
  allHosts,
  setHostsChanged,
  hostsReloading,
  setHostsReloading,
}) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [transportType, setTransportType] = useState('');
  const [routeSize, setRouteSize] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Calculated Data does not recalculate !
  const filterHosts = () => {
    return allHosts.filter((host) => {
      return host.host_type === type;
    });
  };
  const hosts = filterHosts();

  const getAvailableSubnets = async (role, size, amount = 3) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const subnets = await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/available', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        role: role,
        size: size,
        amount: amount,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setError({ message: '', severity: 'success' });
        return data;
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve available subnets!', severity: 'danger' });
        return [];
      });

    return subnets;
  };

  const getAvailableVlans = async (group_tag, amount = 3) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const vlans = await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/vlans/available', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        group_tag: group_tag,
        amount: amount,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setError({ message: '', severity: 'success' });
        return data;
      })
      .catch((error) => {
        console.log(error);
        setError({ message: 'Could not retrieve available vlans!', severity: 'danger' });
        return [];
      });

    return vlans;
  };

  const getAvailableInterfaces = async (role) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const dev_interfaces = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/v1/interfaces/' + role,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setError({ message: '', severity: 'success' });
        return data;
      })
      .catch((error) => {
        console.log(error);
        setError({
          message: 'Could not retrieve available device interfaces!',
          severity: 'danger',
        });
        return [];
      });

    return dev_interfaces;
  };

  useEffect(() => {
    if (!hostsReloading) {
      setError({ message: '', severity: 'success' });
    }
  }, [hostsReloading]);

  // Event Handlers
  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const ip = values.subnet.split('/')[0];
    const hostObj = {
      id: ip,
      reason: values.reason,
    };

    const result = await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/hosts/' + ip, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(hostObj),
    })
      .then((response) => {
        if (response.ok) {
          setHostsChanged(true);
          setHostsReloading(type);
          setError({ message: 'Host deletion has been requested.', severity: 'success' });
          return true;
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not delete host! ' + error, severity: 'danger' });
        return false;
      });
    return result;
  };

  // Main View
  return (
    <Card>
      {type === 'transport' && (
        <Card.Header>
          <Row>
            <Col sm={8}>Transports</Col>
            <Col sm={4}>
              <Dropdown as={NavItem} onSelect={setTransportType}>
                <Dropdown.Toggle as={NavLink}>Create Transport</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey='pppoe'>PPPoE Credentials</Dropdown.Item>
                  <Dropdown.Item eventKey='bgp'>BGP Session</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>
      )}
      {type === 'route' && (
        <Card.Header>
          <Row>
            <Col sm={8}>Routes</Col>
            <Col sm={4}>
              <Dropdown as={NavItem} onSelect={setRouteSize}>
                <Dropdown.Toggle as={NavLink}>Create Route</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey='30'>/30</Dropdown.Item>
                  <Dropdown.Item eventKey='29'>/29</Dropdown.Item>
                  <Dropdown.Item eventKey='28'>/28</Dropdown.Item>
                  <Dropdown.Item eventKey='27'>/27</Dropdown.Item>
                  <Dropdown.Item eventKey='26'>/26</Dropdown.Item>
                  <Dropdown.Item eventKey='25'>/25</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>
      )}
      <Card.Body>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}
        {type === 'transport' && (
          <>
            <EditTransports
              site={site}
              hosts={hosts}
              setHostsChanged={setHostsChanged}
              handleDelete={handleDelete}
              getAvailableVlans={getAvailableVlans}
              getAvailableInterfaces={getAvailableInterfaces}
              hostsReloading={hostsReloading}
              setHostsReloading={setHostsReloading}
              setErrorUpstream={setError}
            />
            <hr />
            <CreateTransport
              site={site}
              setHostsChanged={setHostsChanged}
              transportType={transportType}
              getAvailableSubnets={getAvailableSubnets}
              getAvailableVlans={getAvailableVlans}
              getAvailableInterfaces={getAvailableInterfaces}
              setTransportType={setTransportType}
              hostsReloading={hostsReloading}
              setHostsReloading={setHostsReloading}
              setErrorUpstream={setError}
            />
          </>
        )}
        {type === 'route' && (
          <>
            <EditRoutes
              siteId={site.site_id}
              hosts={hosts}
              handleDelete={handleDelete}
              hostsReloading={hostsReloading}
              setHostsReloading={setHostsReloading}
              setErrorUpstream={setError}
            />
            <hr />
            {routeSize != '' && (
              <CreateRoute
                key={routeSize}
                siteId={site.site_id}
                setHostsChanged={setHostsChanged}
                routeSize={parseInt(routeSize)}
                getAvailableSubnets={getAvailableSubnets}
                setRouteSize={setRouteSize}
                hostsReloading={hostsReloading}
                setHostsReloading={setHostsReloading}
                setErrorUpstream={setError}
              />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

EditHosts.propTypes = {
  type: PropTypes.string,
  site: PropTypes.object,
  allHosts: PropTypes.array,
  setHostsChanged: PropTypes.func,
  hostsReloading: PropTypes.string,
  setHostsReloading: PropTypes.func,
};
