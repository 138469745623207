// External Imports
import { React, Fragment } from 'react';
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table';

export default function ShowCustomerTree({ customerId, customers }) {
  // Calculated Data
  const addParents = (hierarchy, customer_id, level) => {
    let me = null;
    for (const i in customers) {
      if (customers[i].customer_id === customer_id) {
        me = customers[i];
      }
    }
    level--;
    if (me.parent_id != null) {
      hierarchy = addParents(hierarchy, me.parent_id, level);
    }
    hierarchy.push({
      prefix: '--'.repeat(level),
      postfix: '',
      customer_id: me.customer_id,
      name: me.name,
      sites: me.sites,
    });
    return hierarchy;
  };

  const addSiblings = (hierarchy, customer, level) => {
    for (const i in customers) {
      if (customers[i].parent_id === customer.parent_id) {
        hierarchy.push({
          prefix: '--'.repeat(level),
          postfix: customer.customer_id === customers[i].customer_id ? ' (*)' : '',
          customer_id: customers[i].customer_id,
          name: customers[i].name,
          sites: customers[i].sites,
        });
      }
    }
    return hierarchy;
  };

  const addChildren = (hierarchy, customer_id, level) => {
    level++;
    for (const i in customers) {
      if (customers[i].parent_id === customer_id) {
        hierarchy.push({
          prefix: '--'.repeat(level),
          postfix: '',
          customer_id: customers[i].customer_id,
          name: customers[i].name,
          sites: customers[i].sites,
        });
        hierarchy = addChildren(hierarchy, customers[i].customer_id, level);
      }
    }
    return hierarchy;
  };

  const countDirectParents = (customer_id) => {
    for (const i in customers) {
      if (customers[i].customer_id === customer_id) {
        if (customers[i].parent_id == null) {
          return 0;
        } else {
          return countDirectParents(customers[i].parent_id) + 1;
        }
      }
    }
  };

  const getHierarchy = () => {
    let hierarchy = [];

    let me = null;
    for (const i in customers) {
      if (customers[i].customer_id === customerId) {
        me = customers[i];
      }
    }

    let level = countDirectParents(customerId);

    if (me != null) {
      if (me.parent_id != null) {
        hierarchy = addParents(hierarchy, me.parent_id, level);
        hierarchy = addSiblings(hierarchy, me, level);
      } else {
        hierarchy.push({
          prefix: '',
          postfix: ' (*)',
          customer_id: me.customer_id,
          name: me.name,
          sites: me.sites,
        });
      }
      hierarchy = addChildren(hierarchy, me.customer_id, level);
    }
    return hierarchy;
  };

  const hierarchy = getHierarchy();

  return (
    <Table striped>
      <tbody>
        <tr>
          <th>Customer ID</th>
          <th>Customer Name</th>
          <th>Sites</th>
        </tr>
        {hierarchy.map((customer) => (
          <tr key={customer.customer_id}>
            <th>
              {customer.prefix}
              <a className='btn btn-primary' href={'/customers/' + customer.customer_id}>
                {customer.customer_id}
              </a>
              {customer.postfix}
            </th>
            <td>{customer.name}</td>
            <td>
              {customer.sites.map((site_id) => (
                <Fragment key={site_id}>
                  <a className='btn btn-secondary' href={'/sites/' + site_id}>
                    {site_id}
                  </a>
                  <br />
                </Fragment>
              ))}
            </td>
            {/* Show Bandwidth Pools here? Show actual subscriptions?? */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ShowCustomerTree.propTypes = {
  customerId: PropTypes.string,
  customers: PropTypes.array,
};
