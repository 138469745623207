// External Imports
import React, { useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Components
import ProcessingAlert from './alerts/ProcessingAlert';
import CreatePolicy from './qos/CreatePolicy';
import ListPolicies from './qos/ListPolicies';

//Main View
export default function Policies() {
  const [policiesChanged, changePolicies] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'primary' });

  return (
    <>
      <Row>
        <Col>
          <h1 className='text-center'>Configuration - QoS - Policies</h1>
          {policiesChanged && <ProcessingAlert changeFunction={changePolicies} />}
          {error.message != '' && (
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <ListPolicies policiesChanged={policiesChanged} changePolicies={changePolicies} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CreatePolicy policiesChanged={policiesChanged} changePolicies={changePolicies} />
        </Col>
      </Row>
    </>
  );
}
