// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

// Components
import ReloadAlert from '../alerts/ReloadAlert';
import GiveDeleteReason from '../alerts/GiveDeleteReason';

// Hooks
import useToken from '../../utils/useToken';

//List View
export default function ListRatePlans({ ratePlansChanged, changeRatePlans }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [ratePlans, setRatePlans] = useState([]);
  const [deletePlan, setDeletePlan] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  const getRatePlans = async () => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/rate_plans', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setRatePlans(Object.values(data));
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        setError({ message: 'Could not retrieve rate plans!', severity: 'danger' });
      });
  };

  useEffect(() => {
    getRatePlans();
  }, [ratePlansChanged]);

  // Event Handlers
  const showDelete = (event) => {
    setDeletePlan(event.target.value);
  };

  const handleDelete = async (values) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    const planObj = {
      id: deletePlan,
      reason: values.reason,
    };

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/rate_plans/' + deletePlan, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(planObj),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Rate Plan deletion has been requested.', severity: 'success' });
          setDeletePlan('');
          changeRatePlans(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not delete rate plan! ' + error, severity: 'danger' });
      });
  };

  // View
  return (
    <Card>
      <Card.Header>Rate Plans</Card.Header>
      <Card.Body>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}

        <Table striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>MIR</th>
              {/*<th>CIR</th>*/}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {ratePlans.map((plan) => (
              <tr key={plan.plan_name}>
                <th>{plan.plan_name}</th>
                <td>{plan.plan_description}</td>
                <td>
                  {plan.uplink_mir / 1024} / {plan.downlink_mir / 1024} Mbps
                </td>
                {/*             {plan.uplink_cir > 0 || plan.downlink_cir > 0 ? (
                  <td>
                    {plan.uplink_cir / 1024} / {plan.downlink_cir / 1024} Mbps
                  </td>
                ) : (
                  <td>NOT SET</td>
                )}*/}
                <td>
                  {/* Action button for update could go here. NOT SUPPORTED YET! */}
                  {plan.subscriptions.length === 0 ? (
                    deletePlan === plan.plan_name ? (
                      <GiveDeleteReason formHandler={handleDelete} cancelHandler={showDelete} />
                    ) : (
                      <Button variant='warning' value={plan.plan_name} onClick={showDelete}>
                        Delete
                      </Button>
                    )
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

ListRatePlans.propTypes = {
  ratePlansChanged: PropTypes.bool,
  changeRatePlans: PropTypes.func,
};
